import React, { useState, useEffect, useCallback } from "react";
import { Container, Nav, Badge } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import FilterModal from "./FilterModal";
import "./JTSide.css";
import api from "../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faInbox,
  faTable,
  faPlus,
  faFileAlt,
  faComments,
} from "@fortawesome/free-solid-svg-icons";

const JTSide = () => {
  //alert("Xxx")
  const location = useLocation();
  const currentPath = location.pathname;
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [prevUnreadCount, setPrevUnreadCount] = useState(0);

  const userEmail = localStorage.getItem("username");
  const isRamUser = userEmail === "ram@4sight-global.com";

  // Request permission for desktop notifications
  const requestNotificationPermission = useCallback(async () => {
    if (Notification.permission === "default") {
      const permission = await Notification.requestPermission();
      console.log("Notification Permission:", permission);
    }
  }, []);

  // Show desktop notification if allowed
  const showNotification = (message) => {
    if (Notification.permission === "granted") {
      new Notification("Notification", {
        body: message,
        icon: "notification_icon.png",
        requireInteraction: true,
        vibrate: [200, 100, 200],
      });
    } else {
      console.warn("Notifications are blocked or not granted.");
    }
  };

  // Fetch unread messages count and trigger notifications on change
  const fetchUnreadCount = useCallback(async () => {
    try {
      const username = localStorage.getItem("username");
      if (!username) throw new Error("Username not found in local storage");

      const response = await api.get(`discussion/unread/total?username=${username}`);
      const newCount = response.data.total_unread_count || 0;

      if (Notification.permission === "granted") {
        if (newCount > 0 && prevUnreadCount === 0) {
          showNotification("You have unread messages.");
        } else if (newCount > prevUnreadCount) {
          showNotification("New message received!");
        }
      }

      setPrevUnreadCount(newCount);
      setUnreadCount(newCount);
    } catch (error) {
      console.error("Error fetching unread count:", error);
    }
  }, [prevUnreadCount]);

  useEffect(() => {
    requestNotificationPermission();
    fetchUnreadCount();
    const intervalId = setInterval(fetchUnreadCount, 1000);

    return () => clearInterval(intervalId);
  }, [fetchUnreadCount, requestNotificationPermission]);

  // Helper to determine if a nav link is active
  const isActive = (path) => currentPath.startsWith(path) ? "active" : "";

  return (
    <Container fluid className="jt-sidebar-container">
      <aside className="sidebar">
        <Nav className="flex-column">
          {/* Home */}
          <div className="nav-link-container">
            <Nav.Link href="/projects_list" className={`nav-link ${isActive("/projects_list")}`}>
              <FontAwesomeIcon icon={faHome} className="sidebar-list-icon" />
              <span>Home</span>
            </Nav.Link>
          </div>

          {/* Inbox */}
          <div className="nav-link-container">
                <Nav.Link href="/project_inbox" className={`nav-link ${isActive("/project_inbox")}`}>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FontAwesomeIcon icon={faInbox} className="sidebar-list-icon" />
                    {unreadCount > 0 && (
                      <Badge
                        
                        bg="danger"
                        className="always-visible-badge"
                        style={{
                          position: "absolute",
                          left: "10px",   // Adjust as needed
                          top: "5px",    // Adjust as needed
                          right: "0px",  // Adjust as needed
                          fontSize: "0.75rem",
                          zIndex: 2,
                          pointerEvents: "none",
                          textAlign: "left",
                          borderRadius: "100%",
                          padding: "1rem",
                          
                          
                        }}
                      >
                        {unreadCount}
                      </Badge>
                    )}
                  </div>
                  <span>Inbox</span>
                </Nav.Link>
              </div>




          {/* Assign Resource (only for Ram) */}
          {isRamUser && (
            <div className="nav-link-container">
              <Nav.Link href="/assign-resource" className={`nav-link ${isActive("/assign-resource")}`}>
                <FontAwesomeIcon icon={faTable} className="sidebar-list-icon" />
                <span>Assign<br />Resource</span>
              </Nav.Link>
            </div>
          )}

          {/* Add New */}
          <div className="nav-link-container">
            <Nav.Link href="/create-new" className={`nav-link ${isActive("/create-new")}`}>
              <FontAwesomeIcon icon={faPlus} className="sidebar-list-icon" />
              <span>Add New</span>
            </Nav.Link>
          </div>

          {/* Reports */}
          <div className="nav-link-container">
            <Nav.Link href="/project_summary" className={`nav-link ${isActive("/project_summary")}`}>
              <FontAwesomeIcon icon={faFileAlt} className="sidebar-list-icon" />
              <span>Reports</span>
            </Nav.Link>
          </div>

          {/* Performance (only for Ram) */}
          {isRamUser && (
            <div className="nav-link-container">
              <Nav.Link href="/survey_insights" className={`nav-link ${isActive("/survey_insights")}`}>
                <FontAwesomeIcon icon={faComments} className="sidebar-list-icon" />
                <span>Performance</span>
              </Nav.Link>
            </div>
          )}
        </Nav>
      </aside>
      <FilterModal showModal={showFilterModal} setShowModal={setShowFilterModal} />
    </Container>
  );
};

export default JTSide;
