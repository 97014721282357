import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Alert,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import api from "../services/api"; // Ensure your api service is correctly set up

const TSProjectTaskStatus = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [projectSearch, setProjectSearch] = useState("");
  // Initialize tasks as an object because our backend response is grouped by username
  const [tasks, setTasks] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch available projects (segments) on mount
  useEffect(() => {
    const fetchProjects = async () => {
      const token = localStorage.getItem("Token");
      if (!token) {
        setError("No token found in localStorage");
        return;
      }
      try {
        const response = await api.get("api/segments/unique_segments_with_job/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (
          response.status === 200 &&
          response.data?.unique_segment_names &&
          Array.isArray(response.data.unique_segment_names)
        ) {
          setProjects(response.data.unique_segment_names);
          if (response.data.unique_segment_names.length > 0) {
            setSelectedProject(response.data.unique_segment_names[0]);
          }
        } else {
          setProjects([]);
          setError("Failed to fetch projects");
        }
      } catch (err) {
        console.error("Failed to fetch projects:", err);
        setError(err.response?.data?.error || "Failed to fetch projects");
      }
    };

    fetchProjects();
  }, []);

  // Fetch tasks whenever the selectedProject changes
  useEffect(() => {
    const fetchTasks = async () => {
      const token = localStorage.getItem("Token");
      if (!selectedProject) return;
      try {
        setLoading(true);
        setTasks({});
        setError(null);
        // API endpoint expects a query parameter "project"
        const response = await api.get(
          `/timesheet/api/tasks/?project=${encodeURIComponent(selectedProject)}`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        // Our backend returns an object grouped by username.
        // For example: { ram: [ { id: 98, task: "Scripting - Questionnaire review", start_date: "2025-02-18", duration: 330 } ] }
        setTasks(response.data || {});
      } catch (err) {
        console.error("Failed to fetch tasks:", err);
        setError(err.response?.data?.error || "Failed to fetch tasks.");
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [selectedProject]);

  // Handler for project dropdown change
  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
  };

  // Copy table as HTML (with inline styling) to clipboard
  const copyTableAsHtml = () => {
    // If no tasks available, do nothing.
    if (!tasks || Object.keys(tasks).length === 0) return;

    let html = `
      <table style="border-collapse: collapse; width: 100%;">
        <thead>
          <tr>
            <th style="border: 1px solid #000; padding: 8px;">User</th>
            <th style="border: 1px solid #000; padding: 8px;">Task</th>
            <th style="border: 1px solid #000; padding: 8px;">Created At</th>
          </tr>
        </thead>
        <tbody>
    `;

    Object.keys(tasks).forEach((username) => {
      tasks[username].forEach((task, index) => {
        // Display the username once per group using rowSpan.
        if (index === 0) {
          html += `
            <tr>
              <td style="border: 1px solid #000; padding: 8px;" rowspan="${tasks[username].length}">${username}</td>
              <td style="border: 1px solid #000; padding: 8px;">${task.task}</td>
              <td style="border: 1px solid #000; padding: 8px; text-align: right;">${task.start_date}</td>
            </tr>
          `;
        } else {
          html += `
            <tr>
              <td style="border: 1px solid #000; padding: 8px;">${task.task}</td>
              <td style="border: 1px solid #000; padding: 8px; text-align: right;">${task.start_date}</td>
            </tr>
          `;
        }
      });
    });

    html += `
        </tbody>
      </table>
    `;

    const type = "text/html";
    const blob = new Blob([html], { type });
    const clipboardItem = new ClipboardItem({ [type]: blob });
    navigator.clipboard.write([clipboardItem]).then(
      () => {
        alert("Tasks table copied to clipboard with formatting!");
      },
      (err) => {
        console.error("Error copying table as HTML: ", err);
      }
    );
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
        <Typography variant="body1" style={{ marginLeft: "10px" }}>
          Loading tasks...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  // Check if tasks object has any keys
  const hasTasks = tasks && Object.keys(tasks).length > 0;

  // Filter projects based on the search query (case insensitive)
  const filteredProjects = projects.filter((project) =>
    project.toLowerCase().includes(projectSearch.toLowerCase())
  );

  return (
    <Paper elevation={3} style={{ padding: "24px", maxWidth: "800px", margin: "20px auto" }}>
      <Typography variant="h4" gutterBottom>
        Project Tasks
      </Typography>

      {/* Search Box for Projects */}
      <TextField
        label="Search Projects"
        variant="outlined"
        value={projectSearch}
        onChange={(e) => setProjectSearch(e.target.value)}
        fullWidth
        style={{ marginBottom: "20px" }}
      />

      {/* Project Selection Dropdown */}
      <FormControl fullWidth variant="outlined" style={{ marginBottom: "20px" }}>
        <InputLabel>Project</InputLabel>
        <Select value={selectedProject} onChange={handleProjectChange} label="Project">
          {filteredProjects.map((project, index) => (
            <MenuItem key={index} value={project}>
              {project}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Copy Button */}
      <IconButton
        onClick={copyTableAsHtml}
        color="primary"
        aria-label="copy table"
        style={{ marginBottom: "10px" }}
      >
        <ContentCopy />
      </IconButton>

      {/* Tasks Table or Info Message */}
      {!hasTasks ? (
        <Alert severity="info">No tasks available for this project.</Alert>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "200px" }}>Date</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Task</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(() => {
                // Flatten tasks from the grouped object.
                const flattenedTasks = Object.entries(tasks).flatMap(
                  ([username, tasksArr]) =>
                    tasksArr.map((task) => ({
                      username,
                      ...task,
                    }))
                );

                // Group tasks by their start_date.
                const tasksByDate = flattenedTasks.reduce((acc, task) => {
                  const date = task.start_date;
                  if (!acc[date]) {
                    acc[date] = [];
                  }
                  acc[date].push(task);
                  return acc;
                }, {});

                // Get the dates sorted in descending order.
                const sortedDates = Object.keys(tasksByDate).sort(
                  (a, b) => new Date(b) - new Date(a)
                );

                // Render rows grouped by date.
                return sortedDates.map((date) => {
                  const tasksForDate = tasksByDate[date];
                  return tasksForDate.map((task, index) => (
                    <TableRow key={`${task.username}-${task.id}`}>
                      {index === 0 && (
                        <TableCell sx={{ width: "200px" }} rowSpan={tasksForDate.length}>
                          {date}
                        </TableCell>
                      )}
                      <TableCell>{task.username}</TableCell>
                      <TableCell>{task.task}</TableCell>
                    </TableRow>
                  ));
                });
              })()}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default TSProjectTaskStatus;
