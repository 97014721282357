        import React, { useState, useEffect } from 'react';
        import * as XLSX from 'xlsx';
        import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
        import { faTrash, faEye, faEllipsisV, faCircleNotch, faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
        import { Link, useNavigate } from 'react-router-dom';
        import { Table, Button, Dropdown, Alert, Form } from 'react-bootstrap';
        import './DisplayData';
        import './ExcelUpload.css';
        import api from '../services/api';

        const ExcelUpload = () => {
            const [selectedFile, setSelectedFile] = useState(null);
            
            const [isLoading, setIsLoading] = useState(false);
            const [isDone, setIsComplete] = useState(false);
            const [isDuplicate, setIsDuplicate] = useState(false);
            const [fetchedData, setFetchedData] = useState([]);
            const [isDownloadError,setDownloadError]=useState(false)
            const [selectedItems, setSelectedItems] = useState({});
            const [uploadedFiles, setUploadedFiles] = useState([]);
            const [fileData, setFileData] = useState([]);
            const [showTable, setShowTable] = useState(true);
            const [openMenuIndex, setOpenMenuIndex] = useState(null);
            const [showCheckboxes, setShowCheckboxes] = useState(false);
            const navigate = useNavigate();

            useEffect(() => {
                fetchFetchedData();
            }, []);

            const handleFileChange = (e) => {
                setSelectedFile(e.target.files[0]);
            };

            const toggleCheckboxes = () => {
                setSelectedItems({});
                setShowCheckboxes(!showCheckboxes);
                if (showCheckboxes==true){
                    getSelectedFilenames()
                }
                if (!showCheckboxes) {
                    // When turning checkboxes on, reset selections
                    setSelectedItems({});
                }
            };
            const getSelectedFilenames = () => {
                const selectedFilenames = Object.entries(selectedItems)
                    .filter(([filename, isSelected]) => isSelected)
                    .map(([filename]) => filename);
                if (selectedFilenames.length===0){
                    setDownloadError(true)
                    setShowCheckboxes(true)
                }
                else{
                    setDownloadError(false)
                }
                downloadData(selectedFilenames);
                return selectedFilenames;
            };

             // Adjust the import path as needed

            const downloadData = (filenames) => {
            setIsLoading(true); // Show spinner

            api.post('/data/download_data/', { filenames }, { responseType: 'blob' })
                .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'Norms_downloaded_data.csv'; // or 'downloaded_data.xlsx' if the backend sends an Excel file
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                alert('Your file has started downloading.');
                })
                .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
                })
                .finally(() => {
                setIsLoading(false); // Hide spinner
                });
            };
            
            const toggleSelectAll = (checked) => {
                const newSelectedItems = {};
                if (checked) {
                    fetchedData.forEach(file => {
                        newSelectedItems[file.filename] = true;
                    });
                }
                setSelectedItems(newSelectedItems);
            };
            const handleCheckboxChange = (filename, isChecked) => {
                const newSelectedItems = { ...selectedItems, [filename]: isChecked };
                if (!isChecked) {
                    delete newSelectedItems[filename];
                }
                setSelectedItems(newSelectedItems);
            };


            const handleUpload = async () => {
                if (!selectedFile) {    
                    alert("Please select a file to upload.");
                    return;
                }
                setIsLoading(true);
                try {
                    const jsonData = await prepareFileData(selectedFile); // Prepare file data for upload
                    const response = await api.post("data/data/", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Token ${localStorage.getItem("Token")}`,
                        },
                        body: JSON.stringify(jsonData),
                    });
            
                    if (response.ok) {
                        alert("File uploaded successfully.");
                        fetchFetchedData(); // Refresh file list
                    } else {
                        const errorData = await response.json();
                        alert(`Error: ${errorData.error}`);
                    }
                } catch (error) {
                    console.error("Error during upload:", error);
                } finally {
                    setIsLoading(false); // Set loading to false after the process
                }
            };
            
            const prepareFileData = async (file) => {
                const reader = new FileReader();
            
                return new Promise((resolve, reject) => {
                    reader.onload = () => {
                        try {
                            const content = reader.result;
                            const workbook = XLSX.read(content, { type: "binary" });
                            const sheet = workbook.Sheets[workbook.SheetNames[0]];
                            const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            
                            const columns = data[0];
                            if (!columns) {
                                reject("Invalid file format. The first row must contain column headers.");
                                return;
                            }
            
                            const jsonData = {
                                filename: file.name,
                                file_data: data.slice(1).map((row) => {
                                    const obj = {};
                                    columns.forEach((column, index) => {
                                        obj[column] = row[index];
                                    });
                                    return obj;
                                }),
                            };
            
                            resolve(jsonData);
                        } catch (error) {
                            reject("Error processing file data: " + error.message);
                        }
                    };
            
                    reader.onerror = () => {
                        reject("Error reading file.");
                    };
            
                    reader.readAsBinaryString(file);
                });
            };
            
            
            
            const handleError = (error, message) => {
                console.error(message, error);
                alert(message);
            };
            
            const postJSONData = async (jsonData) => {
                setIsLoading(true);
            
                try {
                    const response = await api.post("data/data/", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Token ${localStorage.getItem("Token")}`,
                        },
                        body: JSON.stringify(jsonData),
                    });
            
                    if (response.ok) {
                        const data = await response.json();
                        console.log("Response:", data);
                        setIsComplete(true);
                        fetchFetchedData();
                    } else {
                        const text = await response.text();
                        if (text.startsWith("<!DOCTYPE html>")) {
                            throw new Error("Backend error occurred. Check server logs.");
                        } else {
                            throw new Error(text);
                        }
                    }
                } catch (error) {
                    console.error("Error in postJSONData:", error.message);
                    alert(`Error: ${error.message}`);
                } finally {
                    setIsLoading(false);
                }
            };
            
            
            const postDetails = async () => {
                setIsLoading(true);
                try {
                    const response = await api.post("data/file-details/", {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Token ${localStorage.getItem('Token')}`,
                        },
                        body: JSON.stringify({
                            filename: selectedFile.name,
                            creator: sessionStorage.getItem('username'),
                        }),
                    });
            
                    if (response.ok) {
                        const data = await response.json();
                        setIsLoading(false);
                        return false; // Not a duplicate
                    } else if (response.status === 400) {
                        const errorData = await response.json();
                        if (errorData.error === "Duplicate filename. This file already exists.") {
                            setIsDuplicate(true);
                            setIsLoading(false);
                            return true; // Duplicate entry
                        }
                    }
            
                    throw new Error("Unexpected error occurred.");
                } catch (error) {
                    console.error("Error in postDetails:", error);
                    setIsLoading(false);
                    return true; // Treat as duplicate if an error occurs
                }
            };

            

            const readFile = async (file) => {
                const reader = new FileReader();
                reader.onload = async () => {
                    const content = reader.result;
                    const workbook = XLSX.read(content, { type: "binary" });
                    const sheet = workbook.Sheets[workbook.SheetNames[0]];
                    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            
                    const columns = data[0];
                    if (!columns) {
                        alert("Invalid file format. The first row must contain column headers.");
                        return;
                    }
            
                    const jsonData = {
                        filename: file.name,
                        file_data: data.slice(1).map((row) => {
                            const obj = {};
                            columns.forEach((column, index) => {
                                obj[column] = row[index];
                            });
                            return obj;
                        }),
                    };
            
                    console.log("Prepared JSON Data:", jsonData);
            
                    try {
                        await postJSONData(jsonData);
                    } catch (error) {
                        console.error("Error:", error);
                    }
                };
                reader.readAsBinaryString(file);
            };
            
            
            const processCSV = (content) => {
                const rows = content.split("\n").map((row) => row.split(","));
                const headers = rows[0];
                const jsonData = rows.slice(1).map((row) => {
                    const obj = {};
                    headers.forEach((header, index) => {
                        obj[header.trim()] = row[index]?.trim();
                    });
                    return obj;
                });
                postJSONData(jsonData, selectedFile.name);
            };
            
            const processExcel = (content) => {
                const workbook = XLSX.read(content, { type: "binary" });
                const sheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                postJSONData(jsonData, selectedFile.name);
            };
            

            const handleDeleteRow1 = (index) => {
                const updatedFiles = [...uploadedFiles];
                updatedFiles.splice(index, 1);

                setUploadedFiles(updatedFiles);
            };
            const handleDeleteRow = (filename) => {
                api.delete(`data/deleteProject/?fName=${filename}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Token ${localStorage.getItem('Token')}`,
                    },
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error("Failed to delete file");
                        }

                        const updatedFiles = [...uploadedFiles];
                        // updatedFiles.splice(index, 1);
                        setUploadedFiles(updatedFiles);

                        setFetchedData(prevData => prevData.filter(file => file.filename !== filename));
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            };

            const handleViewSelected = () => {
                setShowCheckboxes(true);
              
                const selectedFilenames = Object.keys(selectedItems);
                if (selectedFilenames.length === 0) {
                  alert("No files selected.");
                  return;
                }
              
                // Assume only one file is selected for now
                const filename = selectedFilenames[0];
              
                api.get(`data/getsummary/?fName=${filename}`, {
                  headers: {
                    Authorization: `Token ${localStorage.getItem('Token')}`,
                  },
                })
                  .then((response) => {
                    console.log("Fetched Data:", response.data);
                    // Navigate to the SummaryCard route with data
                    navigate('/summary', { state: { summaryData: response.data } });
                  })
                  .catch((error) => {
                    console.error("Error fetching data:", error);
                    alert(`Error fetching data for ${filename}.`);
                  });
              };
              
            const handleDeleteSelected = () => {
                setShowCheckboxes(true);
                const selectedFilenames = Object.keys(selectedItems);
                selectedFilenames.forEach(filename => {
                    handleDeleteRow(filename);
                });
            };

            const toggleMenu = (index) => {
                setOpenMenuIndex(openMenuIndex === index ? null : index);
            };

            const closeMenu = () => {
                setOpenMenuIndex(null);
            };

            const fetchFetchedData = () => {
                api.get("data/getPrDetails/", {
                  headers: {
                    Authorization: `Token ${localStorage.getItem('Token')}`,
                  },
                })
                  .then(response => {
                    console.log("Fetched Data:", response.data);
                    setFetchedData(response.data);
                  })
                  .catch(error => {
                    console.error("Error:", error.message);
                  });
              };
              
            return (
                <div>

                    {isDuplicate && (
                        <Alert variant="danger" dismissible onClose={() => setIsDuplicate(false)} style={{display:'flex', justifyContent:'center', alignItems:'center', marginLeft:'500px', marginRight:'500px'}}>
                            <p style={{ color: 'red' }}>Duplicate entry Please check!</p>
                        </Alert>
                    )}
                    {isDownloadError && (
                        <Alert variant="danger" dismissible onClose={() => setDownloadError(false)} style={{display:'flex', justifyContent:'center', alignItems:'center', marginLeft:'500px', marginRight:'500px'}}>
                            <p style={{ color: 'red' }}>Check the projects you want to download and try again!</p>
                        </Alert>
                    )}

                    {isDone && (
                        <Alert variant="success" dismissible onClose={() => setIsComplete(false)} style={{display:'flex', justifyContent:'center', alignItems:'center', marginLeft:'500px', marginRight:'500px'}}>
                            File has been uploaded successfully
                        </Alert>
                    )}

                    {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
            <FontAwesomeIcon icon={faCircleNotch} color="blue" spin size="2x" />
        </div>
    )}


                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '0px', padding: "10" }}>
                    <Form.Control type="file" onChange={handleFileChange}  style={{width:'500px'}} />
                        <button onClick={handleUpload} className="upload-button">Upload</button>
                    </div>
                    <hr />

                    <div style={{display:'flex', border:'1px solid white', borderRadius:'15px', width:'30%', marginLeft: '450px', justifyContent:'space-evenly', backgroundColor:'white', boxShadow:'2px 2px 4px  rgba(10, 10, 10, 10)'}}>
                        <Button variant='success' onClick={toggleCheckboxes} style={{ margin: '10px' }}>
                            <FontAwesomeIcon icon={faDownload} /> 
                        </Button>
                        <Button variant='info' onClick={handleViewSelected} style={{ margin: '10px' }} >
                        <FontAwesomeIcon icon={faEye} /> 
                        </Button>
                        <Button variant='danger' onClick={handleDeleteSelected} style={{ margin: '10px' }}>
                        <FontAwesomeIcon icon={faTrashAlt} /> 
                        </Button>
                    </div>

                    {showTable && (
                        <Table striped bordered hover style={{ width: '95%', marginLeft: '20px', marginRight: 'auto', marginTop: '20px' }}>
<thead>
    <tr>
        {showCheckboxes && (
            <th>
                <input
                    type="checkbox"
                    onChange={e => toggleSelectAll(e.target.checked)}
                    checked={Object.keys(selectedItems).length === fetchedData?.length}
                    disabled={!fetchedData?.length}
                />
            </th>
        )}
        <th>S.No</th>
        <th>File Name</th>
        <th>Sample Size</th>
        {/* <th>Uploaded At</th> */}
        <th>Uploaded By</th>
    </tr>
</thead>

                            <tbody>
        {fetchedData && fetchedData.map((file, index) => (
            <tr key={index}>
                {showCheckboxes && (
                    <td>
                        <input
                            type="checkbox"
                            checked={!!selectedItems[file.filename]}
                            onChange={e => handleCheckboxChange(file.filename, e.target.checked)}
                        />
                    </td>
                )}
                <td>{index + 1}</td>
                <td>{file.filename}</td>
                <td>{file.record_count}</td>
                {/* <td>
                    {new Date(file.uploaded_at).toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                    })}
                </td> */}
                <td>{file.uploaded_by}</td>
            </tr>
        ))}
    </tbody>

                        </Table>
                    )}
                </div>
            );
        };

        export default ExcelUpload;