import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserProfile.css';
import api from '../services/api';
const UserProfile = ({ userId }) => {
  const [profile, setProfile] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(userId);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    department: '',
    reporting_to: '',
    designation: '',
  });

const designations = [
  "Junior Analyst",
  "Senior Analyst",
  "Data Head",
  "Manager",
  "Associate Manager",
  "Senior Project Manager - Field Operations",
  "Associate Director - Insights",
  "Manager – Project Management and Operations",
  "Partner - Insights & Strategy",
  "Associate Manager – Insights",
  "Senior Director - Insights",
  "Senior Executive - Data Processing",
  "Senior Research Executive - Insights",
  "Senior Manager - Insights",
  "Project Manager",
  "Junior Analyst - Data Processing",
  "Senior Consultant",
  "Senior Project Manager",
  "Full-Time Consultant",
  "Chief Executive Officer",
  "Director - Insights",
  "Head of Research & Client Servicing",
  "Manager - Insights",
  "Senior Data Processing Executive",
  "Head of Data Operations",
  "Senior Manager-Project Management and Operations",
  "Manager – Data Processing",
];


  const currentUserEmail = localStorage.getItem('username'); // Current user email

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('users/user-list/');
        setUsers(response.data);

        // Find the user with the matching email in the users list
        const currentUser = response.data.find(user => user.email === currentUserEmail);
        
        // Set the selectedUserId to the current user ID
        if (currentUser) {
          setSelectedUserId(currentUser.id);
        }
      } catch (err) {
        setError('Failed to fetch users');
      }
    };

    fetchUsers();
  }, [currentUserEmail]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await api.get(`users/profile/update/${selectedUserId}/`);
        setProfile(response.data);
        setFormData({
          name: response.data.name || '',
          department: response.data.department || '',
          reporting_to: response.data.reporting_to?.map((rpt) => rpt.id) || [],
          designation: response.data.designation || '',
        });
      } catch (err) {
        setError(err.response?.data?.detail || 'An error occurred');
      }
    };

    if (selectedUserId) {
      fetchUserProfile();
    }
  }, [selectedUserId]);

  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
    setProfile(null);
    setError(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'reporting_to' ? [parseInt(value)] : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.put(
        `users/profile/update/${selectedUserId}/`,
        formData
      );
      setProfile(response.data);
      setIsEditing(false);
    } catch (err) {
      setError(err.response?.data?.detail || 'An error occurred');
    }
  };

  return (
    <div className="container">
      <h1>User Profile</h1>

      {/* Show dropdown only for ram@4sight-global.com */}
      {currentUserEmail === 'ram@4sight-global.com' && (
        <>
          <label htmlFor="user-select">Select User:</label>
          <select id="user-select" value={selectedUserId} onChange={handleUserChange}>
            <option value="">-- Select a user --</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.username} ({user.email})
              </option>
            ))}
          </select>
        </>
      )}

      {!profile && <div>Loading...</div>}

      {profile && !isEditing && (
        <div className="profile-info">
          <p><strong>Name:</strong> {profile.name || 'Not available'}</p>
          <p><strong>Email:</strong> {profile.user?.email || 'Not available'}</p>
          <p><strong>Department:</strong> {profile.department || 'Not available'}</p>
          <p><strong>Designation:</strong> {profile.designation || 'Not available'}</p>
          {profile.reporting_to && profile.reporting_to.length > 0 && (
            <p><strong>Reporting To:</strong>
              {profile.reporting_to.map((rpt, index) => (
                <span key={rpt.id}>
                  {rpt.name}
                  {index < profile.reporting_to.length - 1 && ', '}
                </span>
              ))}
            </p>
          )}

          <div className="button-container">
            <button onClick={() => setIsEditing(true)}>Edit</button>
          </div>
        </div>
      )}

      {profile && isEditing && (
        <form onSubmit={handleSubmit}>
          <div className="select-container">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="select-container">
            <label htmlFor="department">Department:</label>
            <select
              id="department"
              name="department"
              value={formData.department}
              onChange={handleInputChange}
            >
              <option value="">-- Select a department --</option>
              <option value="Data Processing">Data Processing</option>
              <option value="Research">Research</option>
              <option value="Operations">Operations</option>
            </select>
          </div>
          <div className="select-container">
            <label htmlFor="designation">Designation:</label>
            <select
                id="designation"
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
              >
                <option value="">-- Select a designation --</option>
                {designations.map((designation) => (
                  <option key={designation} value={designation}>
                    {designation}
                  </option>
                ))}
              </select>
          </div>
          <div className="select-container">
            <label htmlFor="reporting_to">Reporting To:</label>
            <select
              id="reporting_to"
              name="reporting_to"
              value={formData.reporting_to}
              onChange={handleInputChange}
            >
              <option value="">-- Select a reporting user --</option>
              {users
                .filter((user) => user.id !== selectedUserId)
                .map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.username} ({user.email})
                  </option>
                ))}
            </select>
          </div>
          <div className="button-container">
            <button type="submit">Save</button>
          </div>
        </form>
      )}

      {error && <div className="error">Error: {error}</div>}
    </div>
  );
};

export default UserProfile;
