import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import api from '../services/api';

const ForgotPassword = () => {
  const [userId, setUserId] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1); // Step 1: Request OTP, Step 2: Reset Password
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRequestOtp = async () => {
    try {
      const response = await api.post('users/forgot-password-request/', { user_id: userId });
      setMessage(`OTP has been sent (displayed only in development): ${response.data.otp}`);
      setStep(2);
    } catch (error) {
      setError(error.response?.data?.error || 'Error requesting OTP');
    }
  };

  const handleResetPassword = async () => {
    try {
      await api.post('users/verify-otp-and-reset-password/', {
        user_id: userId,
        otp: otp,
        new_password: newPassword,
      });
      setMessage('Password reset successful. You can now login.');
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      setError(error.response?.data?.error || 'Error resetting password');
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div style={{ width: '300px' }}>
        <h2>Forgot Password</h2>
        {message && <Alert variant="success">{message}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        
        {step === 1 && (
          <>
            <Form.Group controlId="userId">
              <Form.Label>User ID / Username:</Form.Label>
              <Form.Control
                type="text"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                required
              />
            </Form.Group>
            <br />
            <Button variant="primary" onClick={handleRequestOtp}>
              Request OTP
            </Button>
          </>
        )}

        {step === 2 && (
          <>
            <Form.Group controlId="otp">
              <Form.Label>Enter OTP:</Form.Label>
              <Form.Control
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="newPassword">
              <Form.Label>New Password:</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Form.Group>
            <br />
            <Button variant="success" onClick={handleResetPassword}>
              Reset Password
            </Button>
          </>
        )}

        <Button variant="link" onClick={() => navigate('/login')} style={{ marginTop: '10px' }}>
          Back to Login
        </Button>
      </div>
    </Container>
  );
};

export default ForgotPassword;
