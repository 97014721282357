import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Typography, Grid, Badge, Snackbar } from "@mui/material";
import { motion } from "framer-motion";
import api from "../services/api";

import AWNimg from "./compliance.png";
import FSimg from "./checklist.png";
import JTimg from "./job.png";
import NNorms from "./norms.jpg";
import DDrivers from "./drivers.jpg";
import TSheet from "./timesheet.jpg";
import TCheck from "./checklist.jpg";

function Start() {
  const navigate = useNavigate();

  const buttons = [
    { title: "Analysis Warning Note", img: AWNimg, action: () => navigate("/awn") },
    { title: "Image Resizing", img: FSimg, action: () => navigate("/img-resize") },
    { title: "Job Tracker", img: JTimg, action: () => navigate("/projects_list") },
    { title: "Norms", img: NNorms, action: () => navigate("/home") },
    { title: "Driver Analysis", img: DDrivers, action: () => navigate("/driver_analysis") },
    { title: "Timesheet", img: TSheet, action: () => navigate("/timesheet") },
    { title: "Checklists", img: TCheck, action: () => navigate("/documents_and_checklists") },
  ];

  const [unreadCount, setUnreadCount] = useState(0);
  const [prevUnreadCount, setPrevUnreadCount] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const fetchUnreadCount = useCallback(async () => {
    try {
      const username = localStorage.getItem("username");
      if (!username) throw new Error("Username not found in local storage");

      const response = await api.get(`discussion/unread/total?username=${username}`);
      const newCount = response.data.total_unread_count || 0;

      // When count increases, show popup
      if (newCount > prevUnreadCount) {
        setSnackbarOpen(true);
      }

      setPrevUnreadCount(newCount);
      setUnreadCount(newCount);
    } catch (error) {
      console.error("Error fetching unread count:", error);
    }
  }, [prevUnreadCount]);

  useEffect(() => {
    fetchUnreadCount();
    const intervalId = setInterval(fetchUnreadCount, 1000);
    return () => clearInterval(intervalId);
  }, [fetchUnreadCount]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div style={{ backgroundColor: "#f0f2f5", minHeight: "100vh", padding: "40px" }}>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{ textAlign: "center", marginBottom: "30px" }}
      >
        <Typography variant="h4" style={{ fontWeight: "bold", color: "#1976d2" }}>
          Welcome to the Dashboard
        </Typography>
      </motion.div>

      <Grid container spacing={3} justifyContent="center">
        {buttons.map((btn, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Card
                onClick={btn.action}
                style={{
                  cursor: "pointer",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  borderRadius: "12px",
                  transition: "transform 0.3s ease-in-out",
                }}
              >
                <CardContent style={{ display: "flex", alignItems: "center", padding: "20px" }}>
                  <img
                    src={btn.img}
                    alt={btn.title}
                    style={{ width: "50px", height: "50px", marginRight: "15px", borderRadius: "8px" }}
                  />
                  <Typography variant="h6" style={{ fontWeight: "500", color: "#333", flexGrow: 1 }}>
                    {btn.title}
                  </Typography>
                  {btn.title === "Job Tracker" && unreadCount > 0 && (
                    <Badge color="error" badgeContent={unreadCount} />
                  )}
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="You have unread messages"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </div>
  );
}

export default Start;
