import React, { useState, useEffect } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Timesheet.css';
import axios from 'axios';
import { useBootstrap } from './useBootstrap';
import Select from 'react-select';
import { FaCopy } from 'react-icons/fa'; // Import the copy icon
import api from '../services/api';
const localizer = momentLocalizer(moment);

const taskOptionsStatic = [
  // Scripting Tasks
  { value: "scripting_questionnaire_review", label: "Scripting - Questionnaire Review" },
  { value: "scripting_standard_templates", label: "Scripting - Standard Templates (includes template preparation, simple logics, variable naming, and test/live link creation)" },
  { value: "scripting_scripting_logics", label: "Scripting - Scripting Logics (complex logics such as looping, max-diff, product rotation using external files)" },
  { value: "scripting_updating_feedbacks", label: "Scripting - Updating Feedbacks (from researchers, co-scripters, etc.)" },
  { value: "scripting_rework_1", label: "Scripting - Rework 1 (questionnaire revision, revised logics, issues from research)" },
  { value: "scripting_rework_2", label: "Scripting - Rework 2 (issues from scripting)" },
  { value: "scripting_follow_up", label: "Scripting - Follow-Up (technical challenges, discussions with S2C & S2G, platform issues)" },
  { value: "scripting_others", label: "Scripting - Others" },

  // Quality Checks
  { value: "quality_checks_qc_survey", label: "Quality Checks - Survey QC" },
  { value: "quality_checks_qc_co_scripter", label: "Quality Checks - Co-Scripter Survey QC" },
  { value: "quality_checks_others", label: "Quality Checks - Others" },

  // Raw Data Tasks
  { value: "raw_data_extract_share", label: "Raw Data - Extract & Share with Researchers" },
  { value: "raw_data_deletion", label: "Raw Data - Data Deletion (per QC report)" },
  { value: "raw_data_checking", label: "Raw Data - Data Checking" },
  { value: "raw_data_preparation", label: "Raw Data - Data Preparation (e.g., DPCX)" },
  { value: "raw_data_merging", label: "Raw Data - Merging (syntax preparation, merging across platforms, tracker merging)" },
  { value: "raw_data_others", label: "Raw Data - Others" },

  // OE Data Tasks
  { value: "oe_data_preparation", label: "OE Data - Preparation for Coding/Verbatim for Researchers" },
  { value: "oe_data_conversion", label: "OE Data - Conversion to SPSS Format" },
  { value: "oe_data_costing", label: "OE Data - Costing Calculation" },
  { value: "oe_data_others", label: "OE Data - Others" },

  // Database Tasks
  { value: "database_cleaning", label: "Database - Cleaning (formatting, validating email links, etc.)" },
  { value: "database_unique_links", label: "Database - Creating Unique Links" },
  { value: "database_others", label: "Database - Others" },

  // Quota Tasks
  { value: "quota_setup", label: "Quota - Setup and Tracker Updates" },
  { value: "quota_monitoring", label: "Quota - Monitoring & Quota Sheet Preparation" },
  { value: "quota_rework_1", label: "Quota - Rework 1 (due to quota issues/multiple research versions)" },
  { value: "quota_rework_2", label: "Quota - Rework 2 (due to scripting quota issues)" },
  { value: "quota_others", label: "Quota - Others" },

  // Dashboard Tasks
  { value: "dashboard_hsbc", label: "Dashboard - HSBC Dashboards" },

  // General Tasks
  { value: "general_meeting_fun", label: "General - Meetings, Fun Activities, Celebrations, etc." },

  // Upskilling
  { value: "up_skilling_powerbi", label: "Upskilling - Power BI, Python, SPSS, Dimensions, etc." },
  { value: "up_skilling_communication", label: "Upskilling - Communication Skills" },

  // Email & Invitation Tasks
  { value: "email_invitation_preparation", label: "Email Invitation Preparation" },

  // Discussion Tasks
  { value: "discussion_xebo", label: "Discussion with XEBO (regarding issues)" },

  // Translations
  { value: "scripting_translations", label: "Scripting - Translations" },

  // Survey Launching
  { value: "survey_launching", label: "Survey Launching Process" },

  // Quota Issues Discussion
  { value: "quota_issues", label: "Quota Issues (including discussions)" },

  // Template & Logo Tasks
  { value: "template_logos", label: "Template - Logos" },

  // Shared Data
  { value: "shared_sample_data", label: "Shared Sample Data" },

  // Triggered Notifications
  { value: "triggered_email_sms_reminders", label: "Triggered Email / SMS Reminders" },
  { value: "sms_trigger", label: "SMS Trigger" },
  { value: "email_trigger", label: "Email Trigger" },

  // Logic Issues
  { value: "logic_issues", label: "Logic Issues" },

  // DP (Data Processing) Tasks
  { value: "dp_analysis_plan_review", label: "DP - Analysis Plan Review" },
  { value: "dp_questionnaire_review", label: "DP - Questionnaire Review" },
  { value: "dp_template_editing", label: "DP - Template Editing" },
  { value: "dp_template_tables_template_creation", label: "DP - Tables Template Creation" },
  { value: "dp_template_banner_creation", label: "DP - Banner Creation" },
  { value: "dp_template_weighting", label: "DP - Weighting" },
  { value: "dp_template_oe_table_setup", label: "DP - OE Table Setup" },
  { value: "dp_interim_data_tables_generates", label: "DP - Interim Data Tables Generation" },
  { value: "dp_final_data_tables_generates", label: "DP - Final Data Tables Generation" },
  { value: "dp_additional_request", label: "DP - Additional Request" },
  { value: "dp_interim_tables_qc", label: "DP - Interim Tables QC" },
  { value: "dp_final_tables_qc", label: "DP - Final Tables QC" },
  { value: "dp_oe_data_qc", label: "DP - OE Data QC" },

  // Status and Norms
  { value: "status_update", label: "Status Update" },
  { value: "norms", label: "Norms" },

  // Stacked SPSS Tasks
  { value: "stacked_spss_generates", label: "Stacked SPSS Generation" },
  { value: "stacked_spss_qc", label: "Stacked SPSS QC" },

  // SPSS Tasks
  { value: "spss_data_preparation_regression", label: "SPSS - Data Preparation for Regression Analysis" },
  { value: "spss_data_updates", label: "SPSS - Data Updates" },
  { value: "spss_data_merging", label: "SPSS - Data Merging" },
  { value: "spss_recode_cleaning_split", label: "SPSS - Recode, Cleaning, Split" },

  // Not Applicable
  { value: "na", label: "Not Applicable" }
];


function Timesheet() {
  useBootstrap();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [refreshEvents, setRefreshEvents] = useState(false);
  const [events, setEvents] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedTask, setSelectedTask] = useState('');

  // Fetch project options on component mount
  useEffect(() => {
    const token = localStorage.getItem("Token");

    api
      .get('api/segments/unique_segments_with_job/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.data.unique_segment_names?.length > 0) {
          const validSegments = response.data.unique_segment_names.filter(
            (name) => name !== null && name.trim() !== ""
          );
          setProjectOptions(validSegments);
        } else {
          console.error("No unique segment names found");
          setProjectOptions([]);
        }
      })
      .catch((error) => console.error('Error fetching unique segment names:', error));
  }, []);

  // Function to extract the content from the table with id 'summary-table'
  const getTableContent = () => {
    const table = document.getElementById('summary-table');
    if (!table) {
      return "No summary table found.";
    }
    let tableContent = '';
    for (let row of table.rows) {
      for (let cell of row.cells) {
        tableContent += cell.innerText + '\t';
      }
      tableContent += '\n';
    }
    return tableContent;
  };

  // Function to handle copying the summary table to clipboard
  const handleCopySummary = () => {
    const content = getTableContent();
    navigator.clipboard.writeText(content)
      .then(() => {
        alert("Today's summary table copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy summary:", error);
        alert("Failed to copy summary. Please try again.");
      });
  };

  const handleSelectSlot = ({ start, end }) => {
    const startLocal = moment(start).local();
    const endLocal = moment(end).local();

    const dates = [];
    const times = [];
    
    let currentDate = startLocal.clone();
    while (currentDate <= endLocal) {
      dates.push(currentDate.clone());
      currentDate.add(1, 'day');
    }
    setSelectedDates(dates);

    let currentTime = startLocal.clone();
    while (currentTime < endLocal) {
      times.push({
        start: currentTime.clone(),
        end: currentTime.clone().add(30, 'minutes'),
      });
      currentTime.add(30, 'minutes');
    }
    setSelectedTimeSlots(times);
    setShowAddModal(true);
  };

  const handleAddModalClose = () => {
    setShowAddModal(false);
    setSelectedDates([]);
    setSelectedTimeSlots([]);
    setSelectedProject('');
    setSelectedTask('');
    setRefreshEvents((prev) => !prev);
  };

  useEffect(() => {
    const token = localStorage.getItem("Token");

    api
      .get("timesheet/api/events/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          let cumulativeSum = 0;
          const formattedEvents = response.data.flatMap((event) =>
            event.time_slots.map((slot) => {
              const duration = moment.utc(slot.end_time).diff(moment.utc(slot.start_time), "minutes");
              cumulativeSum += duration;
              return {
                id: event.id,
                title: `${event.project || ""}${event.task ? ", " + event.task : ""}`,
                start: moment.utc(slot.start_time).local().toDate(),
                end: moment.utc(slot.end_time).local().toDate(),
                cumulativeSum,
              };
            })
          );
          setEvents(formattedEvents);
        } else {
          console.warn("No events found");
        }
      })
      .catch((error) => console.error("Error fetching events:", error));
  }, [refreshEvents]);

  const handleAddModalSubmit = () => {
    if (!selectedProject) {
      alert("Please select a project!");
      return;
    }
    if (!selectedTask) {
      alert("Please select a task!");
      return;
    }
    if (selectedDates.length === 0 || selectedTimeSlots.length === 0) {
      alert("Please select at least one date and time slot!");
      return;
    }

    const token = localStorage.getItem("Token");
    if (!token) {
      console.error("Token not found");
      return;
    }

    const taskString = selectedTask ? selectedTask.label : null;

    const timeSlots = selectedTimeSlots.map((slot) => ({
      start_time: slot.start.toISOString(),
      end_time: slot.end.toISOString(),
    }));

    api
      .post(
        "timesheet/api/events/",
        {
          project: selectedProject,
          task: taskString,
          start_date: moment(selectedDates[0]).format("YYYY-MM-DD"),
          end_date: moment(selectedDates[selectedDates.length - 1]).format("YYYY-MM-DD"),
          time_slots: timeSlots,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data && response.data.id) {
          const newEvents = timeSlots.map((slot) => ({
            id: response.data.id,
            title: `${selectedProject || ""}${taskString ? ", " + taskString : ""}`,
            start: moment.utc(slot.start_time).local().toDate(),
            end: moment.utc(slot.end_time).local().toDate(),
          }));

          const groupedEvents = newEvents.reduce((acc, event) => {
            if (!acc[event.title]) {
              acc[event.title] = [];
            }
            acc[event.title].push(event);
            return acc;
          }, {});

          setEvents((prevEvents) => {
            const groupedPrevEvents = prevEvents.reduce((acc, event) => {
              if (!acc[event.title]) {
                acc[event.title] = [];
              }
              acc[event.title].push(event);
              return acc;
            }, {});
            return [
              ...Object.values(groupedPrevEvents).flat(),
              ...Object.values(groupedEvents).flat(),
            ];
          });
        } else {
          console.error("Failed to create event: Invalid response", response);
        }
        handleAddModalClose();
      })
      .catch((error) => {
        console.error("Error creating event:", error);
        alert("There was an error creating the event. Please try again.");
      });
  };

  const [deletedEvent, setDeletedEvent] = useState(null);

  const handleDeleteEvent = () => {
    const eventToDelete = selectedEvent;

    api
      .delete(`timesheet/api/events/${eventToDelete.id}/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("Token")}`,
        },
      })
      .then(() => {
        setDeletedEvent(eventToDelete);
        setEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventToDelete.id));
        setRefreshEvents((prev) => !prev);
      })
      .catch((error) => {
        console.error("Error deleting event:", error);
        alert("Failed to delete event. Please try again.");
      });
  };

  // Undo deletion
  const handleUndoDelete = () => {
    if (deletedEvent) {
      api
        .post(
          "timesheet/api/events/",
          {
            project: deletedEvent.project,
            task: deletedEvent.task,
            start_date: moment(deletedEvent.start).format("YYYY-MM-DD"),
            end_date: moment(deletedEvent.end).format("YYYY-MM-DD"),
            time_slots: [
              {
                start_time: moment(deletedEvent.start).toISOString(),
                end_time: moment(deletedEvent.end).toISOString(),
              },
            ],
          },
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("Token")}`,
            },
          }
        )
        .then((response) => {
          setEvents((prevEvents) => [...prevEvents, response.data]);
          setDeletedEvent(null);
          alert("Event restored successfully.");
        })
        .catch((error) => {
          console.error("Error restoring event:", error);
          alert("Failed to restore event. Please try again.");
        });
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedTask(selectedOption);
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    const [project, task] = event.title.split(', ');
    setSelectedProject(project || '');
    setSelectedTask(task || '');
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setSelectedEvent(null);
    setSelectedProject('');
    setSelectedTask('');
  };

  return (
    <div className="timesheet-container">
      <div style={{ padding: '5px 20px 10px 40px', height: '100vh' }}>
        {/* Copy Summary Button with Icon */}
        {/* <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          <button className="btn btn-primary" onClick={handleCopySummary} style={{ display: 'flex', alignItems: 'center' }}>
            <FaCopy style={{ marginRight: '5px' }} />
            Copy Today's Summary Table
          </button>
        </div> */}

        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView="day"
          selectable
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleEventClick}
          step={30}
          timeslots={1}
          components={{
            agenda: {
              event: ({ event }) => (
                <span>
                  {event.title} - {event.cumulativeSum} mins
                </span>
              ),
            },
          }}
        />

        {/* Add Modal */}
        {showAddModal && (
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document" style={{ marginTop: '150px' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add New Event</h5>
                  <button type="button" className="close" aria-label="Close" onClick={handleAddModalClose}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <label>Project Name:</label>
                  <select
                    className="form-control mb-2"
                    value={selectedProject}
                    onChange={(e) => setSelectedProject(e.target.value)}
                  >
                    <option value="" hidden>
                      Select Project
                    </option>
                    {projectOptions.length > 0 ? (
                      projectOptions.map((option, index) => (
                        <option key={`${option}-${index}`} value={option}>
                          {option}
                        </option>
                      ))
                    ) : (
                      <option value="NoProjects">No Projects Available</option>
                    )}
                    {!projectOptions.includes("Others") && (
                      <option key="Others" value="Others">
                        Others
                      </option>
                    )}
                  </select>

                  <label>Task Name:</label>
                  <Select
                    className="mb-2"
                    value={selectedTask}
                    onChange={handleChange}
                    options={taskOptionsStatic}
                    placeholder="Search Task"
                    isSearchable
                  />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-success" onClick={handleAddModalSubmit}>
                    Create Event
                  </button>
                  <button type="button" className="btn btn-danger" onClick={handleAddModalClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Edit Modal */}
        {showEditModal && (
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document" style={{ marginTop: '150px' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Delete Event</h5>
                  <button type="button" className="close" aria-label="Close" onClick={handleEditModalClose}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete Event
                  </button>
                  <button type="button" className="btn btn-secondary" onClick={handleEditModalClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Delete Confirmation Modal */}
        {showDeleteModal && (
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document" style={{ marginTop: '150px' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Delete</h5>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Are you sure you want to delete this event? This action cannot be undone.</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      handleDeleteEvent();
                      setShowDeleteModal(false);
                      setShowEditModal(false);
                    }}
                  >
                    Confirm Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Timesheet;
