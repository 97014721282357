import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarBorderIcon from "@mui/icons-material/StarBorder";

import {
  Typography,
  TextField,
  CircularProgress,
  Alert,
  Paper,
  Badge,
  Box,
  InputAdornment,
  IconButton,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { Send as SendIcon, Search as SearchIcon } from "@mui/icons-material";
import api from "../services/api";

const ChatHeader = ({ userName, userImage, title }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: { xs: "16px", sm: "24px" },
      borderBottom: "2px solid #e5e7eb",
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
      <Box sx={{ position: "relative" }}>
        <Avatar src={userImage} sx={{ width: 40, height: 40 }} />
        <Box
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: 16,
            height: 16,
            borderRadius: "50%",
            backgroundColor: "green",
            border: "2px solid white",
          }}
        />
      </Box>
      <Box>
        <Typography variant="h6" sx={{ color: "#374151" }}>
          {userName}
        </Typography>
        <Typography variant="body2" sx={{ color: "#4b5563" }}>
          {title}
        </Typography>
      </Box>
    </Box>
    <Box>
      <IconButton>
        <SearchIcon />
      </IconButton>
      <IconButton>
        <StarBorderIcon />
      </IconButton>
      <IconButton>
        <VisibilityIcon />
      </IconButton>
    </Box>
  </Box>
);

const ChatInput = ({
  comment,
  setComment,
  handleCommentSubmit,
  loading,
  fetchUsersbySegment,
  users,
  usersLoading, // new prop to indicate loading state for users
  setSelectedMentions,
}) => {
  // Manage anchor state for the context menu
  const [anchorEl, setAnchorEl] = useState(null);

  // Open menu on right-click and trigger fetching users
  const handleContextMenu = (event) => {
    event.preventDefault();
    // Trigger fetch of users
    fetchUsersbySegment();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (name) => {
    setComment((prev) => prev + ` @${name} `);
    // Also track that this username was added
    setSelectedMentions((prev) => [...prev, name]);
    handleMenuClose();
  };
  // When a user selects a name from the menu, append it to the comment
  // const handleMenuItemClick = (name) => {
  //   setComment((prev) => prev + name);
  //   handleMenuClose();
  // };

  return (
    <Box
      component="form"
      onSubmit={handleCommentSubmit}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "16px",
        borderTop: "2px solid #e5e7eb",
      }}
    >
      <TextField
        fullWidth
        multiline
        rows={2}
        placeholder="Write your message!"
        value={comment}
        onContextMenu={handleContextMenu}
        onChange={(e) => setComment(e.target.value)}
        variant="outlined"
        sx={{
          backgroundColor: "#f3f4f6",
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": { borderRadius: "8px" },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                type="submit"
                disabled={loading}
                sx={{
                  backgroundColor: "#3b82f6",
                  color: "white",
                  borderRadius: "50%",
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: "#2563eb",
                  },
                }}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {/* Context menu showing loading state or the user list */}
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleMenuClose}>
        {usersLoading ? (
          <MenuItem disabled>
            <CircularProgress size={20} sx={{ mr: 1 }} /> Loading...
          </MenuItem>
        ) : users && users.length > 0 ? (
          users.map((user, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(user)}>
              {user}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No users found</MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export const JTDiscussionPage = () => {
  //const [comment, setComment] = useState("");
  const [selectedMentions, setSelectedMentions] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const location = useLocation();
  const { segmentId } = location.state || {};
  const email = localStorage.getItem("username");

  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!email) {
      setError("Email ID is missing in localStorage.");
      setLoading(false);
      return;
    }
    const fetchUserProfile = async () => {
      try {
        const response = await api.get(`users/profile/`, {
          headers: { Authorization: `Token ${localStorage.getItem("Token")}` },
        });
        const { username } = response.data;
        setCurrentUserName(username);
      } catch (err) {
        setError("Failed to fetch user information.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [email]);

  useEffect(() => {
    if (!segmentId) {
      setError("Segment ID is missing.");
      setLoading(false);
      return;
    }
    fetchComments();
    fetchUnreadCount(segmentId);
  }, [segmentId]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [comments]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (comment.trim() === "") return;

    // You now have the selectedMentions array available
    console.log("Mentions added:", selectedMentions);

    try {
      setLoading(true);
      const response = await api.post(
        `discussion/create/`,
        {
          segment: segmentId,
          userId: email,
          comment: comment,
          mentions: selectedMentions, // optionally send them to the backend
        },
        {
          headers: { Authorization: `Token ${localStorage.getItem("Token")}` },
        }
      );

      if (response.data) {
        setComments((prevComments) => [...prevComments, response.data]);
      }
      setComment("");
      // Clear the mentions state after sending
      setSelectedMentions([]);
      fetchUnreadCount(segmentId);
    } catch (err) {
      setError("Failed to post comment. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const fetchComments = async () => {
    if (!segmentId) {
      setError("Segment ID is missing.");
      setLoading(false);
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`discussion/list/?segment=${segmentId}`, {
        headers: { Authorization: `Token ${localStorage.getItem("Token")}` },
      });
      const commentsWithUsernames = await Promise.all(
        response.data.map(async (commentData) => {
          const usernameResponse = await api.get(`users/get_username_by_id/`, {
            params: { user_id: commentData.user },
            headers: { Authorization: `Token ${localStorage.getItem("Token")}` },
          });
          if (usernameResponse.data.username) {
            commentData.user = usernameResponse.data.username;
          }
          commentData.is_pinned = commentData.is_pinned || false;
          return commentData;
        })
      );
      setComments(commentsWithUsernames);
    } catch (err) {
      setError("Failed to fetch comments. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchUsersbySegment = async () => {
    try {
      setUsersLoading(true);
      const response = await api.get(
        `api/segments/get_users_by_segment/?segment_id=${segmentId}`,
        {
          headers: { Authorization: `Token ${localStorage.getItem("Token")}` },
        }
      );
      if (response.data) {
        // Use the response data directly since it's a list of user objects.
        setUsers(response.data.map(user => user.username));
      } else {
        throw new Error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]);
    } finally {
      setUsersLoading(false);
    }
  };
  
  const fetchUnreadCount = async (segmentId) => {
    try {
      const response = await api.get(`discussion/unread_count/?segment_id=${segmentId}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data) {
        setUnreadCount(response.data.unreadCount || 0);
      } else {
        throw new Error("Failed to fetch unread count");
      }
    } catch (error) {
      console.error("Error fetching unread count:", error);
      setUnreadCount(0);
    }
  };

  const markCommentAsRead = async (commentId) => {
    setComments((prev) =>
      prev.map((comment) =>
        comment.id === commentId ? { ...comment, is_read: true } : comment
      )
    );
    setUnreadCount((prev) => (prev > 0 ? prev - 1 : 0));
    try {
      await api.post(
        `discussion/mark_as_read/`,
        { segment_id: segmentId, discussion_id: commentId },
        { headers: { Authorization: `Token ${localStorage.getItem("Token")}` } }
      );
    } catch (err) {
      setComments((prev) =>
        prev.map((comment) =>
          comment.id === commentId ? { ...comment, is_read: false } : comment
        )
      );
      setUnreadCount((prev) => prev + 1);
      setError("Failed to mark comment as read.");
    }
  };

  const handlePin = (commentId) => {
    setComments((prev) => {
      const updated = prev.map((comment) => {
        if (comment.is_pinned && comment.id !== commentId) {
          return { ...comment, is_pinned: false };
        }
        return comment;
      });
      return updated.map((comment) =>
        comment.id === commentId
          ? { ...comment, is_pinned: !comment.is_pinned }
          : comment
      );
    });
  };

  return (
    <Paper sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Box
        id="messages"
        sx={{ flex: 1, overflowY: "auto", padding: "16px", backgroundColor: "#fff" }}
      >
        <Typography variant="h6" gutterBottom>
          Project Discussion
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Unread Comments: {unreadCount}
        </Typography>
        {loading && <CircularProgress sx={{ display: "block", margin: "auto" }} />}
        {error && (
          <Alert severity="error" sx={{ marginBottom: "20px" }}>
            {error}
          </Alert>
        )}
        <List>
          {comments.length > 0 ? (
            comments.map((commentData, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  justifyContent:
                    commentData.user === currentUserName ? "flex-end" : "flex-start",
                  marginBottom: "8px",
                }}
                onClick={() => markCommentAsRead(commentData.id)}
              >
                <Paper
                  elevation={2}
                  sx={{
                    padding: "8px 16px",
                    backgroundColor:
                      commentData.user === currentUserName
                        ? "#e3f2fd"
                        : commentData.is_read
                        ? "#f5f5f5"
                        : "#ffebee",
                    borderRadius: "12px",
                    maxWidth: "70%",
                    position: "relative",
                  }}
                >
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="body1" fontWeight="bold">
                          {commentData.user}
                        </Typography>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePin(commentData.id);
                          }}
                        >
                          <StarBorderIcon />
                        </IconButton>
                      </Box>
                    }
                    secondary={
                      <Typography variant="body2" color="textSecondary">
                        <span dangerouslySetInnerHTML={{ __html: commentData.comment }} />
                      </Typography>
                    }
                  />
                  <Box sx={{ position: "absolute", top: 8, right: 8 }}>
                    {commentData.is_read ? (
                      <Badge color="success" badgeContent={<CheckCircleIcon fontSize="small" />} />
                    ) : (
                      <Badge color="error" badgeContent={<VisibilityIcon fontSize="small" />} />
                    )}
                  </Box>
                </Paper>
              </ListItem>
            ))
          ) : (
            <Typography variant="body1" color="textSecondary">
              No comments yet. Be the first to comment!
            </Typography>
          )}
        </List>
        <div ref={messagesEndRef} />
      </Box>

      <ChatInput
        comment={comment}
        setComment={setComment}
        handleCommentSubmit={handleCommentSubmit}
        loading={loading}
        fetchUsersbySegment={fetchUsersbySegment}
        users={users}
        usersLoading={usersLoading}
        setSelectedMentions={setSelectedMentions} // pass down the setter
      />
    </Paper>
  );
};

export default JTDiscussionPage;
