import React from "react";

const LinkedInEmbed = () => {
  // Manually obtained post ID (replace with your actual post ID if needed)
  const postId = "7298232432405692417";

  const containerStyle = {
    textAlign: "center",
    margin: "2rem auto",
    maxWidth: "600px",
    fontFamily: "Arial, sans-serif",
    color: "#0073b1",
  };

  const titleStyle = {
    marginBottom: "1rem",
  };

  const iframeStyle = {
    border: "none",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "1000px",
    height: "798px",
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>Read Our Latest LinkedIn Post</h2>
      <iframe
        src={`https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:${postId}`}
        frameBorder="0"
        allowFullScreen
        title="Embedded LinkedIn Post"
        style={iframeStyle}
      ></iframe>
    </div>
  );
};

export default LinkedInEmbed;
