import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Alert,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import api from "../services/api";

const TSProjectSummary = () => {
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");

  useEffect(() => {
    fetchProjects(); // Load available projects on component mount
  }, []);

  const fetchProjects = async () => {
    try {
      const token = localStorage.getItem("Token");
      if (!token) {
        setError("No token found in localStorage");
        return;
      }

      const response = await api.get("api/segments/unique_segments_with_job/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.status === 200 && response.data?.unique_segment_names) {
        setProjects(response.data.unique_segment_names);
      } else {
        setProjects([]);
        setError("Failed to fetch projects");
      }
    } catch (err) {
      setProjects([]);
      setError(err.response?.data?.error || "Failed to fetch projects");
    }
  };

  const fetchSummary = async () => {
    if (!startDate || !endDate) {
      setError("Please select both Start Date and End Date");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const email = localStorage.getItem("username");
      if (!email) throw new Error("No email found in localStorage");

      const params = new URLSearchParams();
      params.append("email", email);
      params.append("start_date", startDate);
      params.append("end_date", endDate);
      if (selectedProject) params.append("project", selectedProject);

      const response = await api.get(`timesheet/api/project/?${params.toString()}`);
      setSummary(response.data.today_summary || {});
    } catch (err) {
      setSummary({});
      setError(err.response?.data?.error || "Failed to fetch summary");
    } finally {
      setLoading(false);
    }
  };

  const handleFilterSubmit = () => {
    fetchSummary(); // Fetch summary only when "Filter" is clicked
  };

  const copyTableToClipboard = () => {
    if (!summary) return;

    let clipboardText = "";

    Object.keys(summary).sort().forEach((project) => {
      clipboardText += `\n${project}\n`;
      clipboardText += "--------------------------------------\n";
      clipboardText += "Task | Duration (minutes)\n";
      clipboardText += "--------------------------------------\n";

      Object.keys(summary[project]).forEach((task) => {
        clipboardText += `${task} | ${summary[project][task]}\n`;
      });

      clipboardText += "\n";
    });

    navigator.clipboard.writeText(clipboardText);
  };

  return (
    <Paper elevation={3} style={{ padding: "24px", maxWidth: "800px", margin: "20px auto" }}>
      <Typography variant="h4" gutterBottom>
        Time Summary
      </Typography>

      {/* Filters */}
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <TextField
          type="date"
          label="Start Date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
        />
        <TextField
          type="date"
          label="End Date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel id="project-select-label">Project</InputLabel>
          <Select
            labelId="project-select-label"
            value={selectedProject}
            label="Project"
            onChange={(e) => setSelectedProject(e.target.value)}
          >
            <MenuItem value="">
              <em>All Projects</em>
            </MenuItem>
            {projects.map((project, index) => (
              <MenuItem key={index} value={project}>
                {project}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleFilterSubmit}>
          Filter
        </Button>
      </div>

      {error && <Alert severity="error">{error}</Alert>}

      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <CircularProgress />
          <Typography variant="body1" style={{ marginLeft: "10px" }}>
            Loading summary...
          </Typography>
        </div>
      ) : !summary || Object.keys(summary).length === 0 ? (
        <Alert severity="info">No summary available.</Alert>
      ) : (
        <>
          <IconButton onClick={copyTableToClipboard} color="primary" aria-label="copy table" style={{ marginBottom: "10px" }}>
            <ContentCopyIcon />
          </IconButton>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Project</TableCell>
                  <TableCell>Task</TableCell>
                  <TableCell align="right">Duration (minutes)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(summary).map((project, projIndex) =>
                  Object.keys(summary[project]).map((task, taskIndex) => (
                    <TableRow key={`${projIndex}-${taskIndex}`}>
                      {taskIndex === 0 && (
                        <TableCell rowSpan={Object.keys(summary[project]).length}>
                          {project}
                        </TableCell>
                      )}
                      <TableCell>{task}</TableCell>
                      <TableCell align="right">{summary[project][task]}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Paper>
  );
};

export default TSProjectSummary;
