import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Form, Button, Alert, Container, Row, Col, Spinner, ListGroup } from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa'; // Import trash icon
import api from '../services/api';
const DriverDataUpload = ({ setColumns, setFileId, setBinaryColumns }) => {
    const [file, setFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const fileInputRef = useRef(null);

    useEffect(() => {
        setErrorMessage('');
        fetchUploadedFiles();
    }, []);

    const fetchUploadedFiles = async () => {
        setErrorMessage('');
        try {
            const response = await api.get('drivers/list_files/', {
                headers: {
                    Authorization: `Token ${localStorage.getItem('Token')}`,
                },
            });
            const files = response.data.map((file) => ({
                name: file.name,
                id: file.name,
                columns: [],
                binaryColumns: [],
            }));
            setUploadedFiles(files);
        } catch (error) {
            console.error('Error fetching files:', error);
            setErrorMessage('Failed to load files. Please try again.');
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileUpload = async () => {
        setSuccessMessage('');
        if (!file) {
            setErrorMessage('Please select a file to upload.');
            return;
        }
        if (uploadedFiles.length >= 5) {
            setErrorMessage('You can only upload a maximum of 5 files. Please delete a file first.');
            return;
        }
        else{
        const formData = new FormData();
        formData.append('file', file);

        setIsUploading(true);
        try {
            const response = await api.post('drivers/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${localStorage.getItem('Token')}`,
                },
            });

            const fileDetails = {
                name: file.name,
                id: response.data.file_id,
                columns: response.data.columns || [],
                binaryColumns: response.data.binary_columns || [],
            };

            setUploadedFiles((prevFiles) => [...prevFiles, fileDetails]);
            setColumns(fileDetails.columns);
            setBinaryColumns(fileDetails.binaryColumns);
            setFileId(fileDetails.id);
            setErrorMessage('');
            setFile(null);
            setSuccessMessage('File uploaded successfully.');

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } catch (error) {
            console.error('Upload Error:', error.response || error.message);
            setErrorMessage('Error uploading file. Please try again.');
        } finally {
            setIsUploading(false);
        }
    }
    };

    const handleLoadFile = async (fileId) => {
        setErrorMessage('');
        const selectedFile = uploadedFiles.find((file) => file.id === fileId);
        if (selectedFile) {
            if (!selectedFile.columns || selectedFile.columns.length === 0) {
                try {
                    const response = await api.get(`drivers/file_columns/${fileId}/`, {
                        headers: {
                            Authorization: `Token ${localStorage.getItem('Token')}`,
                        },
                    });

                    const updatedFile = {
                        ...selectedFile,
                        columns: response.data.columns || [],
                        binaryColumns: response.data.binary_columns || [],
                    };

                    setUploadedFiles((prevFiles) =>
                        prevFiles.map((file) => (file.id === fileId ? updatedFile : file))
                    );
                } catch (error) {
                    console.error('Error fetching file columns:', error);
                    setErrorMessage('Failed to load file columns.');
                    return;
                }
            }

            setColumns(selectedFile.columns);
            setBinaryColumns(selectedFile.binaryColumns);
            setFileId(selectedFile.id);
            setSuccessMessage(`File "${selectedFile.name}" loaded successfully.`);
        } else {
            setErrorMessage('File not found in the list.');
        }
    };

    const handleFileDelete = async (fileName) => {
        setErrorMessage('');
        setSuccessMessage('');
        try {
            const response = await api.delete('drivers/delete_file/', {
                data: { file_name: fileName },
                headers: {
                    Authorization: `Token ${localStorage.getItem('Token')}`,
                },
            });
            setSuccessMessage(response.data.message);
            window.location.reload();
            setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
        } catch (error) {
            console.error('Error deleting file:', error.response || error.message);
            setErrorMessage('Error deleting file. Please try again.');
        }
    };

    return (
        <Container fluid>
            <Row className="mt-4">
                <Col md={8} className="p-3">
                    <h3 className="text-center mb-4">Upload SPSS Data for Driver Analysis</h3>
                    <Form>
                        <Form.Group controlId="fileUpload">
                            <Form.Label className="custom-label">Select SPSS File:</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleFileChange}
                                accept=".sav"
                                ref={fileInputRef}
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            className="mt-3"
                            onClick={handleFileUpload}
                            disabled={isUploading}
                        >
                            {isUploading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    Uploading...
                                </>
                            ) : (
                                'Upload'
                            )}
                        </Button>
                    </Form>
                    {errorMessage && (
                        <Alert variant="danger" className="mt-3">
                            {errorMessage}
                        </Alert>
                    )}
                    {successMessage && (
                        <Alert variant="success" className="mt-3">
                            {successMessage}
                        </Alert>
                    )}
                </Col>

                <Col md={4} className="bg-light p-3">
                    <h5>Uploaded Files</h5>
                    {uploadedFiles.length > 0 ? (
                        <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                            <ListGroup>
                                {uploadedFiles.map((file) => (
                                    <ListGroup.Item
                                        key={file.id}
                                        action
                                        onClick={() => handleLoadFile(file.id)}
                                    >
                                        {file.name}
                                        <FaTrashAlt
                                            className="float-right text-danger"
                                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleFileDelete(file.name);
                                            }}
                                        />
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    ) : (
                        <p>No files uploaded yet.</p>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default DriverDataUpload;
