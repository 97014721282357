import React, { useState } from "react";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import {
  AiOutlineHome,
  AiOutlineFunnelPlot,
  AiOutlineMessage,
  AiOutlineBook,
  AiOutlineTable,
  AiOutlineMenu,
} from "react-icons/ai";
import FilterModal from "./FilterModal";
import "./SideNavbar.css";

const navItems = [
  { href: "/home", label: "Home", icon: <AiOutlineHome /> },
  { href: "/cross-tab", label: "CrossTab", icon: <AiOutlineTable /> },
  { href: "/chatbot", label: "ChatBot", icon: <AiOutlineMessage /> },
  { href: "/norms", label: "Norms", icon: <AiOutlineBook /> },
  { href: "/filters", label: "Filters", icon: <AiOutlineFunnelPlot /> },
];

const SideNavbar = () => {
 // alert("XXX")
  const location = useLocation();
  const currentPath = location.pathname;
  const [showSidebar, setShowSidebar] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const renderNavItem = (item) => (
    <div className="nav-link-container" key={item.href}>
      <Nav.Link
        as={Link}
        to={item.href}
        className={`nav-link ${currentPath === item.href ? "active" : ""}`}
        onClick={() => setShowSidebar(false)}
      >
        {item.icon}
        <span>{item.label}</span>
      </Nav.Link>
    </div>
  );

  return (
    <>
      {/* Mobile Navbar Toggle */}
      <Navbar className="d-lg-none bg-dark text-white p-2">
        <AiOutlineMenu
          size={30}
          onClick={() => setShowSidebar(true)}
          className="ms-3"
        />
        <h5 className="mx-auto my-0">4Sight Research</h5>
      </Navbar>

      {/* Desktop Sidebar */}
      <div className="sidebar d-none d-lg-flex flex-column">
        <Nav className="flex-column">{navItems.map(renderNavItem)}</Nav>
      </div>

      {/* Mobile Offcanvas Sidebar */}
      <Offcanvas
        show={showSidebar}
        onHide={() => setShowSidebar(false)}
        responsive="lg"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Navigation</Offcanvas.Title>
        </Offcanvas.Header>
        {/* <Offcanvas.Body>
          <Nav className="flex-column">{navItems.map(renderNavItem)}</Nav>
        </Offcanvas.Body> */}
      </Offcanvas>

      <FilterModal showModal={showFilterModal} setShowModal={setShowFilterModal} />
    </>
  );
};

export default SideNavbar;
