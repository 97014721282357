import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Avatar,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import api from '../services/api';
import JTMonthWiseReport from "./JTMonthWiseReport";
import JTStartMonthSummary from "./JTStartMonthSummary";
import './JTSegmentSummary.css';
import CummulativeSummary from "./CummulativeSummary";

// Custom styled Tab component
const CustomTab = styled(Tab)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
}));

const JTSegmentSummary = () => {
  const [completedMonths, setCompletedMonths] = useState([]);
  const [contactPersonsDP, setContactPersonsDP] = useState([]);
  const [contactPersonsScripting, setContactPersonsScripting] = useState([]);
  const [selectedDP, setSelectedDP] = useState("");
  const [selectedScripting, setSelectedScripting] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [summaryDataDP, setSummaryDataDP] = useState(null);
  const [summaryDataScripting, setSummaryDataScripting] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedScriptingIndex, setSelectedScriptingIndex] = useState(0);

  // Fetch data for dropdowns
  useEffect(() => {
    const fetchContactPersons = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("Token");

        const [dpResponse, scriptingResponse, monthResponse] = await Promise.all([
          api.get("api/segments/fetch_dp_contacts/", {
            headers: { Authorization: `Token ${token}` },
            params: { user: localStorage.getItem('username') },
          }),
          api.get("api/segments/fetch_scripting_contacts/", {
            headers: { Authorization: `Token ${token}` },
            params: { user: localStorage.getItem('username') },
          }),
          api.get("api/segments/project_summary/", {
            headers: { Authorization: `Token ${token}` },
          }),
        ]);

        setContactPersonsDP(dpResponse.data || []);
        setContactPersonsScripting(scriptingResponse.data || []);
        const uniqueMonths = [
          ...new Set(monthResponse.data.map((item) => item.completed_month)),
        ].filter(Boolean);
        setCompletedMonths(uniqueMonths.sort());
      } catch (err) {
        setError("Failed to fetch dropdown data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchContactPersons();
  }, []);

  // Fetch summary data
  const fetchSummaryData = async (name, type) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("Token");
      const response = await api.get("api/segments/fetch_summary/", {
        params: { name, type_of_work: type },
        headers: { Authorization: `Token ${token}` },
      });

      if (type === "dp") {
        setSummaryDataDP(response.data);
      } else if (type === "scripting") {
        setSummaryDataScripting(response.data);
      }
    } catch (err) {
      setError("Failed to fetch summary data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Render binary attributes
  const renderBinaryAttributes = (summaryData) => {
    if (!summaryData?.binary_summary) return null;
    return (
      <Card className="mb-4">
        <CardHeader title="Binary Checks" />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Attribute</TableCell>
                <TableCell>Yes</TableCell>
                <TableCell>No</TableCell>
                <TableCell>Blanks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(summaryData.binary_summary).map(([field, counts]) => (
                <TableRow key={field}>
                  <TableCell>{field.replace(/_/g, " ").toUpperCase()}</TableCell>
                  <TableCell>{counts.Yes || 0}</TableCell>
                  <TableCell>{counts.No || 0}</TableCell>
                  <TableCell>{counts.Null || 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  };

  // Render categorical counts
  const renderCategoricalCounts = (summaryData) => {
    if (!summaryData?.categorical_counts) return null;
    return (
      <Card className="mb-4">
        <CardHeader title="Categorical Counts" />
        <CardContent>
          {Object.entries(summaryData.categorical_counts).map(([key, value], index) => (
            <Accordion key={key}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{key.replace(/_/g, " ").toUpperCase()}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {typeof value === "object" ? (
                  <List>
                    {Object.entries(value).map(([subKey, subValue]) => (
                      <ListItem key={subKey}>
                        <ListItemText primary={subKey.replace(/_/g, " ")} secondary={`Count: ${subValue}`} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography>{value || "N/A"}</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </CardContent>
      </Card>
    );
  };

  // Render numeric aggregations
  const renderNumericAggregations = (summaryData) => {
    if (!summaryData?.numeric_aggregations) return null;
    return (
      <Card className="mb-4 shadow-lg border-0">
        <CardHeader title="Numeric Aggregations" />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Metric</TableCell>
                <TableCell>Values</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(summaryData.numeric_aggregations).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key.replace(/_/g, " ").toUpperCase()}</TableCell>
                  <TableCell>{value || "N/A"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  };

  const handleNext = (type) => {
    if (type === "dp") {
      const nextIndex = (selectedIndex + 1) % contactPersonsDP.length;
      setSelectedIndex(nextIndex);
      setSelectedDP(contactPersonsDP[nextIndex]);
      fetchSummaryData(contactPersonsDP[nextIndex], "dp");
    } else {
      const nextIndex = (selectedScriptingIndex + 1) % contactPersonsScripting.length;
      setSelectedScriptingIndex(nextIndex);
      setSelectedScripting(contactPersonsScripting[nextIndex]);
      fetchSummaryData(contactPersonsScripting[nextIndex], "scripting");
    }
  };

  const handlePrev = (type) => {
    if (type === "dp") {
      const prevIndex = selectedIndex === 0 ? contactPersonsDP.length - 1 : selectedIndex - 1;
      setSelectedIndex(prevIndex);
      setSelectedDP(contactPersonsDP[prevIndex]);
      fetchSummaryData(contactPersonsDP[prevIndex], "dp");
    } else {
      const prevIndex = selectedScriptingIndex === 0 ? contactPersonsScripting.length - 1 : selectedScriptingIndex - 1;
      setSelectedScriptingIndex(prevIndex);
      setSelectedScripting(contactPersonsScripting[prevIndex]);
      fetchSummaryData(contactPersonsScripting[prevIndex], "scripting");
    }
  };

  return (
    <Container className="mt-5">
      <Tabs
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
        aria-label="Segment summary tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        <CustomTab label="Report by DP" value="tab1" />
        <CustomTab label="Report by Scripting" value="tab2" />
        <CustomTab label="Report by Start Month" value="tab4" />
        <CustomTab label="Report by Completed month" value="tab3" />
        <CustomTab label="Cumulative Summary" value="tab5" />
      </Tabs>
      <Box className="tab-content-container">
        {activeTab === "tab1" && (
          <div>
            <Typography variant="h5" align="center">SUMMARY BY DP CONTACT PERSON</Typography>
            {loading && (
              <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <CircularProgress />
                <Typography variant="body1" ml={2}>Loading...</Typography>
              </Box>
            )}
            {error && <Alert severity="error">{error}</Alert>}
            {!loading && (
              <>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <IconButton onClick={() => handlePrev("dp")} disabled={contactPersonsDP.length === 0}>
                    <ArrowBack />
                  </IconButton>
                  {contactPersonsDP.length > 0 && (
                    <Card
                      className={`m-2 p-3 text-center card-person border-primary`}
                      variant="outlined"
                    >
                      <CardContent>
                        <Box display="flex" flexDirection="column" alignItems="center">
                          <Avatar>{contactPersonsDP[selectedIndex][0]}</Avatar>
                          <Typography variant="body1">{contactPersonsDP[selectedIndex]}</Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                  <IconButton onClick={() => handleNext("dp")} disabled={contactPersonsDP.length === 0}>
                    <ArrowForward />
                  </IconButton>
                </Box>
                {selectedDP && (
                  <>
                    <Typography variant="h6" align="center">Data for: {selectedDP}</Typography>
                    <Typography variant="h6" align="center">Total No of Segments: {summaryDataDP?.total_no_of_segments}</Typography>
                    {renderNumericAggregations(summaryDataDP)}
                    {renderCategoricalCounts(summaryDataDP)}
                    {renderBinaryAttributes(summaryDataDP)}
                  </>
                )}
              </>
            )}
          </div>
        )}
        {activeTab === "tab2" && (
          <div>
            <Typography variant="h5" align="center">Summary by Contact Person for Scripting</Typography>
            {loading && (
              <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <CircularProgress />
                <Typography variant="body1" ml={2}>Loading...</Typography>
              </Box>
            )}
            {error && <Alert severity="error">{error}</Alert>}
            {!loading && (
              <>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <IconButton onClick={() => handlePrev("scripting")} disabled={contactPersonsScripting.length === 0}>
                    <ArrowBack />
                  </IconButton>
                  {contactPersonsScripting.length > 0 && (
                    <Card
                      className={`m-2 p-3 text-center card-person border-primary`}
                      variant="outlined"
                    >
                      <CardContent>
                        <Box display="flex" flexDirection="column" alignItems="center">
                          <Avatar>{contactPersonsScripting[selectedScriptingIndex][0]}</Avatar>
                          <Typography variant="body1">{contactPersonsScripting[selectedScriptingIndex]}</Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                  <IconButton onClick={() => handleNext("scripting")} disabled={contactPersonsScripting.length === 0}>
                    <ArrowForward />
                  </IconButton>
                </Box>
                {selectedScripting && (
                  <>
                    <Typography variant="h6" align="center">Data for: {selectedScripting}</Typography>
                    <Typography variant="h6" align="center">Total No of Segments: {summaryDataScripting?.total_no_of_segments}</Typography>
                    {renderNumericAggregations(summaryDataScripting)}
                    {renderCategoricalCounts(summaryDataScripting)}
                    {renderBinaryAttributes(summaryDataScripting)}
                  </>
                )}
              </>
            )}
          </div>
        )}
        {activeTab === "tab4" && (
          <JTStartMonthSummary
            completedMonths={completedMonths}
            fetchSummaryData={fetchSummaryData}
            loading={loading}
            error={error}
          />
        )}
        {activeTab === "tab3" && (
          <JTMonthWiseReport
            completedMonths={completedMonths}
            fetchSummaryData={fetchSummaryData}
            loading={loading}
            error={error}
          />
        )}
        {activeTab === "tab5" && <CummulativeSummary />}
      </Box>
    </Container>
  );
};

export default JTSegmentSummary;