import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Button, Card, Form, Table, Alert, Modal } from 'react-bootstrap';
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './Survey.css';
import LogoImg from './4sightlogo1.png';
import api from '../services/api';
const questions = [
  { questionText: 'Overall Performance of the DP Or Scripting team' },
  { questionText: 'Quality of the Script' },
  { questionText: 'Timelines of the Script Delivery' },
  { questionText: 'Quality of the Data Tables' },
  { questionText: 'Timelines of the Data Tables Delivery' },
  { questionText: "DP/Scripter's Quality of Communication and Explanation" },
  { questionText: "Availability and Support" },
  { questionText: 'Anything Specific you would like to Highlight about the Study?', inputField: true },
];

const SurveyPerformance = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { surveyId } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [answers, setAnswers] = useState(questions.map(() => ''));
  const [surveyData, setSurveyData] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState([]);

  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        const response = await api.get(`survey/survey/${surveyId}/`, { 
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('Token')}`,
          },
        });
        // Use response.data if using Axios
        setSurveyData(response.data);
      } catch (error) {
        console.error("Error fetching survey data:", error);
      }
    };
    fetchSurveyData();
  }, [surveyId]);
  
  useEffect(() => {
    let filtered = [];
    if (surveyData.scripting_in_charge !== 'Not Applicable' || surveyData.dp_in_charge !== 'Not Applicable') {
      filtered = questions.filter((question) => {
        if (question.questionText.includes('Script')) {
          return surveyData.scripting_in_charge !== 'Not Applicable';
        }
        if (question.questionText.includes('Data Tables')) {
          return surveyData.dp_in_charge !== 'Not Applicable';
        }
        return true;
      });
    }
    setFilteredQuestions(filtered);
  }, [surveyData]);

  const handleSubmit = async () => {
    if (!surveyId) {
      console.error("Cannot submit responses. Survey ID is missing.");
      return;
    }

    const responses = filteredQuestions.map((question, index) => ({
      Question: `Q${index + 1}`,
      Response: answers[index] || '',
    }));

    try {
      const response = await api.post(
        `survey/survey/${surveyId}/submit-responses/`,
        { responses },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('Token')}`,
          },
        }
      );
      // Remove response.ok check for Axios.
    
      const segmentUpdateResponse = await api.post(
        'api/segments/update-feedback-status/',
        {
          segment_id: surveyData.segment_id,
          feedback_survey_done: 'Yes',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('Token')}`,
          },
        }
      );
    
      setShowScore(true);
    } catch (error) {
      console.error('Error in Submitting:', error);
    }

  };

  const handleNextQuestion = () => {
    if (!answers[currentQuestion]) {
      setShowAlert(true); 
      return;
    }
    setShowAlert(false);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < filteredQuestions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      handleSubmit();
    }
  };

  const handleAnswerOptionClick = (answer) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestion] = answer;
    setAnswers(updatedAnswers);
    setShowAlert(false);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < filteredQuestions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      handleSubmit();
    }
  };

  return (
    <Container fluid className="mt-4">
      {showScore ? (
        <div className="text-center my-5">
          <Card className="p-4 shadow-sm">
            <Card.Body>
              <h4>Thank You!</h4>
              <p>Your responses have been recorded.</p>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center flex-column">
          {currentStep === 0 ? (
            <Card className="p-4 shadow-sm w-75 my-5">
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <img src={LogoImg} alt="Logo" style={{ width: '100px' }} />
                  <Button onClick={() => setCurrentStep(1)}>Continue</Button>
                </div>
                <Table bordered className="mt-4">
                  <tbody>
                    <tr><td>Researcher in Charge</td><td>{surveyData.researcher_in_charge}</td></tr>
                    <tr><td>Project Name</td><td>{surveyData.project_name}</td></tr>
                    <tr><td>Job Number</td><td>{surveyData.job_number}</td></tr>
                    <tr><td>Year</td><td>{surveyData.year}</td></tr>
                    <tr><td>Month</td><td>{surveyData.month}</td></tr>
                    <tr><td>Scripting in Charge</td><td>{surveyData.scripting_in_charge}</td></tr>
                    <tr><td>DP in Charge</td><td>{surveyData.dp_in_charge}</td></tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          ) : (
            filteredQuestions.map((question, index) => (
              <Transition key={index} in={index === currentQuestion} timeout={500} classNames="cards">
                <Card className="p-4 shadow-sm w-50 my-4" style={{ display: index === currentQuestion ? 'block' : 'none' }}>
                  <Card.Body>
                    <Card.Title className="text-center">{question.questionText}</Card.Title>
                    {!question.inputField && (
                      <div className="d-flex justify-content-around my-3">
                        {['Excellent', 'Good', 'Average', 'Poor', 'Very Poor'].map((answer) => (
                          <Button
                            key={answer}
                            variant={answers[currentQuestion] === answer ? 'primary' : 'outline-secondary'}
                            onClick={() => handleAnswerOptionClick(answer)}
                          >
                            {answer}
                          </Button>
                        ))}
                      </div>
                    )}
                    {question.inputField && (
                      <>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter your response"
                          value={answers[currentQuestion]}
                          onChange={(e) => {
                            const updatedAnswers = [...answers];
                            updatedAnswers[currentQuestion] = e.target.value;
                            setAnswers(updatedAnswers);
                          }}
                          className="my-3"
                        />
                      </>
                    )}
                    {showAlert && (
                      <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                        Please select an answer for this question before proceeding.
                      </Alert>
                    )}
                    <div className="d-flex justify-content-between mt-3">
                      {currentQuestion > 0 && (
                        <Button variant="outline-secondary" onClick={() => setCurrentQuestion(currentQuestion - 1)}>
                          <FontAwesomeIcon icon={faAngleLeft} /> Back
                        </Button>
                      )}
                      <Button variant="outline-primary" onClick={handleNextQuestion} className="py-2 px-4">
                        {currentQuestion < filteredQuestions.length - 1 ? 'Next' : 'Submit'}
                        <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Transition>
            ))
          )}
        </div>
      )}
    </Container>
  );
};

export default SurveyPerformance;