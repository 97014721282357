import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Alert,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Avatar,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  Comment as CommentIcon,
  ArrowForward as ArrowForwardIcon,
  Create as CreateIcon,
} from "@mui/icons-material";
import "./JTInbox.css";
import api from "../services/api";

const JTInbox = () => {
  // Unread messages state
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Compose form states
  const [composeOpen, setComposeOpen] = useState(false);
  const [composeComment, setComposeComment] = useState("");
  
  // Instead of selecting a segment directly, we now select a project.
  // Each project object should include job_number, segment_name, and project_name.
  const [selectedProject, setSelectedProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [cascadingNames, setCascadingNames] = useState([]);

  const navigate = useNavigate();
  const email = localStorage.getItem("username");

  // Show desktop notification for new messages
  const showNotification = (message) => {
    if (Notification.permission === "granted") {
      new Notification("New Message", {
        body: `${message.poster_name}: ${message.comment}`,
        icon: "/notification_icon.png", // Replace with a valid icon URL
      });
    }
  };

  // Fetch unread messages every 5 seconds
  useEffect(() => {
    let intervalId;
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        console.log("Notification permission:", permission);
      });
    }

    const fetchUnreadMessages = async () => {
      try {
        if (!email) throw new Error("Username not found in local storage");
        const response = await api.get(
          `discussion/project_unread_messages/?username=${email}`
        );
        const newMessages = response.data.unread_messages || [];
        if (newMessages.length > unreadMessages.length) {
          showNotification(newMessages[0]);
        }
        setUnreadMessages(newMessages);
      } catch (error) {
        console.error("Error fetching unread messages:", error);
        setError("An error occurred while fetching unread messages.");
      } finally {
        setLoading(false);
      }
    };

    fetchUnreadMessages();
    intervalId = setInterval(fetchUnreadMessages, 50000);
    return () => clearInterval(intervalId);
  }, [unreadMessages, email]);

  // -----------------------------
  // Fetch cascading names (from user profile)
  // -----------------------------
  useEffect(() => {
    const token = localStorage.getItem("Token");
    if (!email || !token) return;
    api
      .get(`users/cascading_names/?email=${email}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        setCascadingNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching cascading names:", error);
      });
  }, [email]);

  // -----------------------------
  // Fetch projects using cascading names
  // -----------------------------
  useEffect(() => {
    const token = localStorage.getItem("Token");
    if (!token) return;
    if (cascadingNames.length === 0) return;
    // Build query parameter string: e.g., "name=Name1&name=Name2..."
    const cascadingNamesParam = cascadingNames.join("&name=");
    api
      .get(`api/segments/fetch-segments-by-name?name=${cascadingNamesParam}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        // Expect each project object to include job_number, segment_name, and project_name
        const data = response.data;
        setProjects(data);
      })
      .catch((error) =>
        console.error("Error fetching projects:", error)
      );
  }, [cascadingNames]);

  // Handle clicking on an unread message row to navigate to discussion
  const handleListItemClick = (segmentId) => {
    navigate("/discussion", { state: { segmentId } });
  };

  // Handle compose submission:
  // 1. Ensure a project is selected and comment is entered.
  // 2. Fetch the segment ID using the project's job number and segment name.
  // 3. Post the comment using the fetched segment ID.
  const handleComposeSubmit = async (e) => {
    e.preventDefault();
    if (!selectedProject || composeComment.trim() === "") {
      setError("Please select a project and enter a comment.");
      return;
    }
    try {
      setLoading(true);
      const projectObj = JSON.parse(selectedProject);
      const { job_number, segment_name } = projectObj;
      // Fetch the segment ID based on job_number and segment_name
      const segResponse = await api.get(
        `api/segments/fetch_segment_id_by_job_and_name?job_number=${job_number}&segment_name=${segment_name}`
      );
      const { segmentId } = segResponse.data;
      if (!segmentId) {
        setError("Segment ID not found for the selected project.");
        return;
      }
      // Post the discussion comment using the fetched segment ID
      await api.post(
        `discussion/create/`,
        {
          segment: segmentId,
          userId: email,
          comment: composeComment,
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
          },
        }
      );
      setComposeComment("");
      setSelectedProject("");
      setComposeOpen(false);
      // Optionally, refresh unread messages or navigate to discussion page
    } catch (err) {
      console.error("Error posting comment:", err);
      setError("Failed to post comment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-5 h-screen w-full bg-blue-600">
      <div className="h-full bg-white rounded-xl shadow-xl overflow-hidden flex flex-col">
        {/* Header */}
        <div className="border-b px-5 py-3 flex justify-between items-center">
          <Typography variant="h4" className="text-slate-600 font-bold">
            Unread Messages
          </Typography>
          <Button
            variant="contained"
            startIcon={<CreateIcon />}
            onClick={() => setComposeOpen((prev) => !prev)}
          >
            {composeOpen ? "Cancel" : "Compose"}
          </Button>
        </div>
        <Divider />
        {/* Compose Form */}
        {composeOpen && (
          <form className="p-5 border-b" onSubmit={handleComposeSubmit}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="project-select-label">Select Project</InputLabel>
              <Select
                labelId="project-select-label"
                id="project-select"
                value={selectedProject}
                label="Select Project"
                onChange={(e) => setSelectedProject(e.target.value)}
              >
                {projects.map((proj, index) => (
                  <MenuItem
                    key={index}
                    value={JSON.stringify(proj)}
                  >
                    {proj.project_name} &mdash; {proj.segment_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Your Message"
              multiline
              rows={3}
              fullWidth
              value={composeComment}
              onChange={(e) => setComposeComment(e.target.value)}
              variant="outlined"
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary">
              Send Message
            </Button>
          </form>
        )}
        {/* Content */}
        <div className="p-5 flex-1 overflow-auto">
          {loading ? (
            <div className="flex flex-col items-center justify-center h-full">
              <CircularProgress color="primary" />
              <Typography variant="body1" className="mt-3">
                Loading unread messages...
              </Typography>
            </div>
          ) : error ? (
            <Alert severity="error" className="mt-5">
              {error}
            </Alert>
          ) : unreadMessages.length === 0 ? (
            <Alert severity="info" className="mt-5">
              No unread messages found.
            </Alert>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="font-semibold text-slate-600">
                      Avatar
                    </TableCell>
                    <TableCell className="font-semibold text-slate-600">
                      Segment Name
                    </TableCell>
                    <TableCell className="font-semibold text-slate-600">
                      Poster
                    </TableCell>
                    <TableCell className="font-semibold text-slate-600">
                      Comments
                    </TableCell>
                    <TableCell className="font-semibold text-slate-600">
                      Timestamp
                    </TableCell>
                    <TableCell
                      align="right"
                      className="font-semibold text-slate-600"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unreadMessages.map((message, index) => (
                    <TableRow
                      key={index}
                      className="hover:bg-slate-100 cursor-pointer"
                      onClick={() => handleListItemClick(message.segment_id)}
                    >
                      <TableCell>
                        <Avatar>
                          <CommentIcon />
                        </Avatar>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {message.segment_name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {message.poster_name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">
                          {message.comment.split("\n")[0]}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {new Date(message.timestamp).toLocaleString()}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handleListItemClick(message.segment_id)}
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default JTInbox;
