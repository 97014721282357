import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ExportButton = () => {
  const exportTableToExcel = () => {
    const table = document.getElementById('normsTable');
    if (!table) {
      alert("Table not found!");
      return;
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(table);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, 'NormsTable.xlsx');
  };

  return (
    <button className="btn btn-success" onClick={exportTableToExcel}>
      Export to Excel
    </button>
  );
};

export default ExportButton;
