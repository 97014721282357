import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import AuthContext from '../Components/AuthContext';
import api from '../services/api';

const Login = () => {
  const { handleLogin } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post('users/login/', {
        email: email,
        password: password,
      });

      const { success, username, token, last_login } = response.data;

      if (success) {
        handleLogin(username || email, token, last_login);
        localStorage.setItem('token', token);
        localStorage.setItem('Token', token);
        localStorage.setItem('lastLoggedIn', last_login);
        navigate('/');
      } else {
        setError('Invalid email or password');
      }
    } catch (error) {
      setError(error.response?.data?.error || 'Invalid email or password');
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div style={{ width: '300px' }}>
        <Form onSubmit={handleLoginSubmit}>
          <h2>Login</h2>
          <Form.Group controlId="email">
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <br />
          <Button variant="primary" type="submit" style={{ marginBottom: '10px' }}>
            Login
          </Button>
        </Form>
        {error && <Alert variant="danger">{error}</Alert>}
        
        <Button variant="link" onClick={() => navigate('/forgot-password')} style={{ width: '100%' }}>
          Forgot Password?
        </Button>

        <Button
          variant="secondary"
          onClick={() => navigate('/signup')}
          style={{ marginTop: '10px', width: '100%' }}
        >
          Sign Up
        </Button>
      </div>
    </Container>
  );
};

export default Login;
