import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Spinner,
  Toast,
  ToastContainer,
  Pagination,
  Alert,
  Container,
} from "react-bootstrap";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../services/api"; // Custom Axios instance
import "../Components/JTProjectList.css";

const JTResourceUnassigned = () => {
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchResources = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await api.get("api/segments/fetch-unassigned-projects");
        setResources(response.data);
      } catch (err) {
        setError("An error occurred while fetching resources.");
      } finally {
        setLoading(false);
      }
    };

    fetchResources();
  }, []);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = resources.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container className="mt-4">
      <h3 className="text-center mb-4">Project List - Assign Resources</h3>

      {loading && (
        <div className="d-flex justify-content-center align-items-center my-4">
          <Spinner animation="border" variant="primary" />
          <p className="ms-2">Loading resources...</p>
        </div>
      )}

      {error && <Alert variant="danger">{error}</Alert>}

      {!loading && !error && resources.length === 0 && (
        <Alert variant="info">No unassigned resources found.</Alert>
      )}

      {!loading && resources.length > 0 && (
        <>
          <div className="table-responsive">
            <Table striped bordered hover className="shadow-sm">
              <thead className="table-dark text-center">
                <tr>
                  <th>#</th>
                  <th>Month</th>
                  <th>Project Name</th>
                  <th>Job Number</th>
                  <th>Segment Name</th>
                  <th>Research Contact</th>
                  <th>Scripting Contact</th>
                  <th>DP Contact</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {currentItems.map((resource, index) => (
                  <tr key={resource.id}>
                    <td>{indexOfFirstItem + index + 1}</td>
                    <td>{resource.month || "-"}</td>
                    <td>{resource.project_name || "-"}</td>
                    <td>{resource.job_number || "-"}</td>
                    <td>{resource.segment_name || "-"}</td>
                    <td>{resource.contact_person_in_research || "-"}</td>
                    <td>{resource.contact_person_for_scripting || "-"}</td>
                    <td>{resource.contact_person_for_dp || "-"}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faEdit}
                        className="text-primary cursor-pointer"
                        title="Assign"
                        onClick={() =>
                          navigate("/edit-resource", {
                            state: {
                              segmentId: resource.id,
                              jobNumber: resource.job_number,
                              editMode: true,
                            },
                          })
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Pagination */}
          <div className="d-flex justify-content-center mt-3">
            <Pagination>
              {Array.from({ length: Math.ceil(resources.length / itemsPerPage) }).map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </>
      )}

      {/* Toast Notification */}
      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          onClose={() => setShowSuccessToast(false)}
          show={showSuccessToast}
          delay={3000}
          autohide
          bg="success"
        >
          <Toast.Header>
            <strong className="me-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>{successMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  );
};

export default JTResourceUnassigned;
