import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AuthContext, { AuthProvider } from './Components/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute';
import NavigationBar from './Components/NavigationBar';
import SideNavbar from './Components/SideNavbar';
import JTSide from './Components/JTSide';
import TSSide from './Components/TSSide';
import Operations from './Components/Operations';
import Awn1 from './Components/Awn1';
import Login from './Components/Login';
import SignUpForm from './Components/SignUpForm';
import Home from './Components/Home';
import DisplayData from './Components/DisplayData';
import FileUpload from './Components/ExcelUpload';
import CrossTab from './Components/CrossTab';
import Chatbot from './Components/Chatbot';
import Norms from './Components/Norms';
import Filters from './Components/Filters';
import SurveyDetails from './Components/SurveyDetails';
import JTProjectList from './Components/JTProjectList';
import DocumentManager from "./Components/DocumentManager";
import Conclusion from './Components/Conclusion';
import SegView from './Components/SegView';
import JTNewEntry from './Components/JTNewEntry';
import Survey from './Components/Survey';
import DriverDataUpload from './Components/DriverDataUpload';
import DriverAnalysisForm from './Components/DriverAnalysisForm';
import NormsProjectSummaryCard from './Components/NormsProjectSummaryCard';
import JTSegmentSummary from './Components/JTSegmentSummary';
import ForgotPassword from './Components/ForgotPassword';
import './App.css';
import SurveyInsights from './Components/SurveyInsights';
import DriverUserManual from './Components/DriverUsermanual';
import DriverAnalysisGuide from './Components/DriverAnalysisGuide';
import Timesheet from './Components/Timesheet';
import JTDiscussionPage from './Components/JTDiscussionPage'
import UserProfile from './Components/UserProfile';
import ImageResizer from './Components/ImageResizer';
import JTInbox from './Components/JTInobx';
import TodaySummary from './Components/TodaySummary';
import FilterSummary from './Components/TFlterSummary';
import TSProjectSummary from './Components/TSProjectSummary';
import JTResourceUnassigned from './Components/JTResourceUnassigned';
import EditResource from './Components/EditResource';
import TSTSProjectTaskStatus from './Components/TSProjectTaskStatus';
import LinkedInEmbed  from './Components/LinkedIn';
import SurveySide from './Components/SurveySide';
import Homepage from './Components/HomePage';

function App() {
  const [fileData, setFileData] = useState([]);

  return (
    <AuthProvider>
      <Router>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppContent fileData={fileData} setFileData={setFileData} />
        </LocalizationProvider>
      </Router>
    </AuthProvider>
  );
}

const AppContent = ({ fileData, setFileData }) => {
  const location = useLocation();

  const [columns, setColumns] = useState([]);
  const [binaryColumns, setBinaryColumns] = useState([]);
  const [fileId, setFileId] = useState(null);
  

  // Sidebar paths
  const sidebarPaths = [
    '/home',
    '/display-data',
    '/upload',
    '/cross-tab',
    '/chatbot',
    '/norms',
    '/filters',
     // Include the new path here
  ];

  // Sidebar paths
  const sideSurveyPaths = [
    '/home',
    '/researcher-wise',
    '/project-wise',
    '/survey_insights',
  ];


  const JTsidebarPaths = [
    '/projects_list',
    '/project_inbox',
    '/create-new',
    '/edit',
    '/project_detailed_view',
    '/project_summary',
    '/discussion',
    '/assign-resource',
  ];
  const TSsidebarPaths = [
    '/timesheet-home',
    '/timesheet',
    '/timesheet-today',
    '/timesheet-date',
    '/timesheet-project',
  ];
  const SummaryCardWrapper = () => {
    const location = useLocation();
    const { summaryData } = location.state || {};

    if (!summaryData) {
        return <div>No summary data available.</div>;
    }

    return <NormsProjectSummaryCard summaryData={summaryData} />;
};

//sideSurveyPaths
  // Determine which sidebar to show
  const showSidebar = sidebarPaths.some(path => location.pathname.startsWith(path));
  const showSideSurveybar = sideSurveyPaths.some(path => location.pathname.startsWith(path));

  const showJTSidebar = JTsidebarPaths.some(path => location.pathname.startsWith(path));
  const showTSSidebar = TSsidebarPaths.some(path => location.pathname.startsWith(path));
  //(showJTSidebar)
  return (
    <>
      <NavigationBar />
      <Container fluid>
        <Row>
          <AuthContext.Consumer>
            {({ isLoggedIn }) =>
              isLoggedIn && (
                <>
                  {showSidebar && (
                    <Col sm={1} className="p-0">
                      <SideNavbar />
                    </Col>
                  )}

                {showSideSurveybar && (
                    <Col sm={1} className="p-0">
                      <SurveySide />
                    </Col>
                  )}
                  {showJTSidebar && (
                    <Col sm={1} className="p-0">
                      <JTSide />
                      
                    </Col>
                  )}
                  {showTSSidebar && (
                    <Col sm={1} className="p-0">
                      <TSSide />
                    </Col>
                  )}
                </>
              )
            }
          </AuthContext.Consumer>
          <Col sm={showSidebar || showSideSurveybar || showJTSidebar || showTSSidebar? 11 : 12 } className="p-0">
            <Routes>
              {/* Public Routes */}
              {/* <Route path="/" element={<Homepage />} /> */}
              <Route path="/" element={<Operations />} />
              
              <Route path="/linkedin" element={<LinkedInEmbed  />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/signup" element={<SignUpForm />} />
              
              <Route path="/driver_analysis_user_manual" element={<DriverUserManual />} />
              <Route path="/driver_analysis_guide" element={<DriverAnalysisGuide />} />

              {/* Protected Routes */}
              <Route path="/awn" element={<ProtectedRoute><Awn1 /></ProtectedRoute>}/>
              <Route path="/update-profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
              <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
              <Route
                path="/display-data/:fileName"
                element={<ProtectedRoute><DisplayData fileData={fileData} /></ProtectedRoute>}
              />
              <Route
                path="/upload"
                element={<ProtectedRoute><FileUpload setFileData={setFileData} /></ProtectedRoute>}
              />
              <Route path="/img-resize" element={<ProtectedRoute><ImageResizer /></ProtectedRoute>} />
              <Route path="/cross-tab" element={<ProtectedRoute><CrossTab /></ProtectedRoute>} />
              <Route path="/chatbot" element={<ProtectedRoute><Chatbot /></ProtectedRoute>} />
              <Route path="/norms" element={<ProtectedRoute><Norms /></ProtectedRoute>} />
              <Route path="/filters" element={<ProtectedRoute><Filters /></ProtectedRoute>} />
              <Route path="/survey_details" element={<ProtectedRoute><SurveyDetails /></ProtectedRoute>} />
              <Route path="/survey_insights" element={<ProtectedRoute><SurveyInsights /></ProtectedRoute>} />
              <Route path="/summary" element={<ProtectedRoute><SummaryCardWrapper /></ProtectedRoute>} />
              <Route path="/projects_list" element={<ProtectedRoute><JTProjectList /></ProtectedRoute>} />
              <Route path="/project_inbox" element={<ProtectedRoute><JTInbox /></ProtectedRoute>} />
              <Route
                path="/satisfactionsurvey/:surveyId"
                element={<Survey />}
              />
              <Route path="/documents_and_checklists" element={<ProtectedRoute><DocumentManager /></ProtectedRoute>} />
              <Route path="/assign-resource" element={<ProtectedRoute><JTResourceUnassigned /></ProtectedRoute>} />
              <Route path="/create-new" element={<ProtectedRoute><JTNewEntry /></ProtectedRoute>} />
              <Route path="/edit" element={<ProtectedRoute><JTNewEntry /></ProtectedRoute>} />
              <Route path="/project_summary" element={<ProtectedRoute><JTSegmentSummary /></ProtectedRoute>} />
              <Route path="/discussion" element={<ProtectedRoute><JTDiscussionPage /></ProtectedRoute>} />
              <Route path="/edit-resource" element={<ProtectedRoute><EditResource /></ProtectedRoute>} />

               <Route path="/conclusion" element={<ProtectedRoute><Conclusion /></ProtectedRoute>} />
               <Route path="/project_detailed_view" element={<ProtectedRoute><SegView /></ProtectedRoute>} />
               <Route path="/timesheet" element={<ProtectedRoute><Timesheet /></ProtectedRoute>} />
               <Route path="/timesheet-today" element={<ProtectedRoute><TodaySummary /></ProtectedRoute>} />
               <Route path="/timesheet-date" element={<ProtectedRoute><FilterSummary /></ProtectedRoute>} />
               <Route path="/timesheet-project" element={<ProtectedRoute><TSProjectSummary/></ProtectedRoute>} />
               <Route path="/timesheet-task-status" element={<ProtectedRoute><TSTSProjectTaskStatus/></ProtectedRoute>} />
              {/* Driver Analysis Path */}
              <Route
                path="/driver_analysis"
                element={
                  <div >
                    <Col >
                    <Row>
                      <Col>
                      <ProtectedRoute> <DriverDataUpload
                      setColumns={setColumns}
                      setFileId={setFileId}
                      setBinaryColumns={setBinaryColumns}
                    /></ProtectedRoute>
                    </Col>
                    </Row>
                    <Row>
                      <Col>
                    {Array.isArray(columns) && columns.length > 0 && (
                      <ProtectedRoute>
                      <DriverAnalysisForm
                        columns={columns}
                        binaryColumns={binaryColumns} // Pass binaryColumns as-is
                        fileId={fileId}
                      /></ProtectedRoute>
                    )}
                    </Col>
                    </Row>
                    </Col>
                  </div>
                }
              />


              {/* Redirect any undefined paths to home */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default App;