import React, { useContext, useState } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './NavigationBar.css';
import logo from '../Components/4sightlogo.png';
import AuthContext from './AuthContext';

const NavigationBar = () => {
  
  const { isLoggedIn, username, handleLogout } = useContext(AuthContext);
  
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleNavigation = (path) => {
    navigate(path);
    setExpanded(false); // Close menu on navigation
  };

  const onLogoutClick = () => {
    handleLogout();
    navigate('/');
    setExpanded(false);
  };

  return (
    <Navbar 
      expand="lg" 
      style={{ backgroundImage: "linear-gradient(to right, rgb(7, 72, 83), rgb(138 223 157))" }} 
      fixed="top"
      expanded={expanded} 
      className="px-3 custom-navbar"
    >
      <Container fluid className="d-flex align-items-center justify-content-between">
        {/* Logo Section - Original Size */}
        <Navbar.Brand as={Link} to="/" className="navbar-logo">
          <img 
            src={logo} 
            alt="4sightlogo"
            className="original-logo"
            style={{ width: '80%', height: '80%' }}
          />
        </Navbar.Brand>

        {/* Navbar Toggler for Mobile */}
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav" 
          onClick={() => setExpanded(expanded ? false : true)}
        />
        

        <Navbar.Collapse id="basic-navbar-nav">
          {/* Title Section - Centered and Unaffected by Username */}
          

          {/* Right Section (Dropdown) */}
          <Nav className="ms-auto navbar-dropdown-container">
            <NavDropdown
              title={isLoggedIn ? <span className="username">Hi - {username}</span> : <span style={{ color: 'white' }}>Login</span>}
              id="basic-nav-dropdown"
              className="custom-dropdown"
            >
              {!isLoggedIn && (
                <NavDropdown.Item as={Link} to="/login" onClick={() => setExpanded(false)}>Login</NavDropdown.Item>
              )}
              <NavDropdown.Item as={Link} to="/update-profile" onClick={() => setExpanded(false)}>Profile</NavDropdown.Item>
              <NavDropdown.Divider />
              {isLoggedIn && (
                <NavDropdown.Item onClick={onLogoutClick}>Logout</NavDropdown.Item>
              )}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
