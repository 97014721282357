import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Form, Row, Col, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./JTSegmentDetails.css"; // Custom CSS for additional styling
import { useNavigate } from "react-router-dom";
import axios from "axios";
import api from '../services/api';

const JTSegmentDetails = forwardRef(({ formData, handleChange, errors = {}, segmentIndex = 0 }, ref) => {
  const navigate = useNavigate();
  const [error, setError] = useState(""); // For general error messages
  const [successMessage, setSuccessMessage] = useState(""); // For success messages
  const [showSuccessToast, setShowSuccessToast] = useState(false); // Controls success toast visibility

  // New state variables for contact persons arrays
  const [contactPersonsDP, setContactPersonsDP] = useState([]);
  const [contactPersonsScripting, setContactPersonsScripting] = useState([]);
  const [contactPersonsResearch, setContactPersonsResearch] = useState([]);

  // Fetch the names and departments and then update the arrays based on department
  useEffect(() => {
    api.get('users/names-departments/', {
      headers: {
        Authorization: `Token ${localStorage.getItem("Token")}`
      }
    })
    .then((response) => {
      // response.data is expected to be an array of objects with name and department
      const profiles = response.data;
      const dpNames = profiles
        .filter(profile => profile.department === "Data Processing")
        .map(profile => profile.name);
      const researchNames = profiles
        .filter(profile => profile.department === "Research")
        .map(profile => profile.name);
      // Set both DP and Scripting arrays from Data Processing profiles
      setContactPersonsDP(dpNames);
      setContactPersonsScripting(dpNames);
      // Set Research names for Research related fields
      setContactPersonsResearch(researchNames);
    })
    .catch((error) => {
      console.error("Error fetching contact persons:", error);
      setError("Failed to load contact person data");
    });
  }, []);

  // Other dropdown options remain unchanged
  const contactPersonsResearchFallback = ['Ananya', 'Ashish', 'Aruna', 'Afaq', 'Caroline Maina', 'Elias Korban', 'Krati', 'Kunal', 'Lina Almani', 'Mahima Choudhary','Manoj Kumar', 'Mohamed Ismail', 'Muhammed Raafat', 'Nour Sheikh Sulaiman', 'Priya', 'Pulkit', 'Rita KFoury', 'Saranya', 'Sonal', 'Tanuj Guptha', 'Vinivaa','Not Applicable'];
  const projectStatus = ["Open", "Closed", "Hold"];
  const analysisGroupHead = ['Ramamoorthy'];
  const advancedAnalyticsOptions = [
    'Chi-Square Analysis', 'Correlation Analysis', 'Driver Analysis',
    'Jaccard Analysis', 'Max-diff analysis', 'Others', 'Not Applicable'
  ];
  const surveyPlatformOptions = ['Forsta', 'SurveyToGo', 'XEBO', 'Others', 'Not Applicable'];
  const projectClassification = ['Class A', 'Class B', 'Class C', 'Class D','Class E'];
  const dpPlatformOptions = ['IBM Dimensions', 'IBM SPSS', 'Q Research Platform', 'Quantum', 'Others', 'Not Applicable'];

  const researchFields = [
    "segment_name",
    "actual_sample_size",
    "achieved_sample_size",
    "final_processed_samples",
    "research_group_head",
    "contact_person_in_research",
    "analysis_group_head",
    "contact_person_for_scripting",
    "contact_person_for_dp",
    "status"
  ];

  const dateFields = [
    "date_of_awn",
    "qnr_received_date",
    "ap_received_date",
    "latest_topline_date",
    "expected_date_of_link",
    "actual_date_of_link",
    "fw_start_date",
    "fw_end_date",
    "expected_date_of_final_output",
    "actual_date_of_final_output",
  ];

  const yesNoFields = [
    "data_correction_done",
    "check_list_followed",
    "feedback_survey_done",
    "norms_done",
    "archive_taken",
    "oe_coding",
    "backup_taken",
  ];

  const additionalFields = [
    "completed_month",
    "backup_folderpath",
    "oe_costing",
    "no_of_top_lines",
    "advanced_analytics",
    "survey_platform",
    "dp_platform",
    "project_classification",
    "remarks",
  ];

  const numericFields = [
    "actual_sample_size",
    "achieved_sample_size",
    "final_processed_samples",
    "loi",
    "oe_costing",
    "number_of_response",
  ];

  useImperativeHandle(ref, () => ({
    formData,
    handleStatusChange: async (value, feedbackDone) => {
      handleChange('status', value);
  
      if (value === 'Closed' && feedbackDone !== 'Yes') {
        const {
          segment_name,
          actual_date_of_final_output,
          completed_month,
          contact_person_in_research = 'Not Available',
          contact_person_for_scripting = 'Not Applicable',
          contact_person_for_dp = 'Not Applicable',
          job_number,
          project_name
        } = formData;
  
        try {
          // Fetch segment ID using both job_number and segment_name
          const response = await api.get(
            `api/segments/fetch_segment_id_by_job_and_name/`, {
              params: {
                job_number: job_number,
                segment_name: segment_name,
              },
              headers: {
                Authorization: `Token ${localStorage.getItem("Token")}`,
                "Content-Type": "application/json",
              },
            }
          );
  
          console.log("Full Response Data:", response.data);
  
          const segmentId = response.data.segmentId;
  
          if (!segmentId) {
            console.error("Segment ID is missing from the response.");
            alert("Failed to retrieve segment ID.");
            return;
          }
  
          const staticData = {
            researcherInCharge: contact_person_in_research,
            projectName: `${project_name} ${segment_name}`,
            jobNumber: job_number,
            segmentId: segmentId,
            year: new Date(actual_date_of_final_output).getFullYear().toString(),
            month: completed_month,
            scriptingInCharge: contact_person_for_scripting,
            dpInCharge: contact_person_for_dp,
          };
  
          console.log("Static Data with Segment ID:", staticData);
  
          navigate("/survey_details", { state: { data: staticData } });
        } catch (error) {
          console.error("Error fetching segment ID:", error.message);
          alert("Failed to fetch segment ID");
        }
      }
    }
  }), [formData, handleChange, navigate]);
  
  const handleNumericChange = (field, value) => {
    const numericValue = value === "" ? null : value.replace(/[^0-9.]/g, "");
    handleChange(field, numericValue ? Number(numericValue) : null);
  };

  const renderFields = (fields) =>
    fields.map((field, index) => {
      const isError = errors[`${field}_${segmentIndex}`];
      return(
        <Col xs={12} md={6} className="mb-3" key={index}>
          <div className="field-container">
            <Form.Label className="highlighted-label mb-1">
              {field.replace(/_/g, " ").toUpperCase()}
            </Form.Label>
            {field.toLowerCase().includes("date") ? (
              <DatePicker
                selected={formData[field] ? new Date(formData[field]) : null}
                onChange={(date) => {
                  if (date) {
                    const localDate = new Date(
                      date.getTime() - date.getTimezoneOffset() * 60000
                    ).toISOString().split("T")[0];
                    handleChange(field, localDate);
                  } else {
                    handleChange(field, null);
                  }
                }}
                dateFormat="yyyy-MM-dd"
                placeholderText="Select a date"
                className={`form-control custom-datepicker ${isError ? "is-invalid" : ""}`}
              />
            ) : yesNoFields.includes(field) ? (
              <Form.Select
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={!!isError}
              >
                <option value="">Select Yes/No</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="NA">Not Applicable</option>
              </Form.Select>
            ) : field === "status" ? (
              <Form.Select
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={!!isError}
              >
                <option value="">Select Project Status</option>
                {projectStatus.map((status, idx) => (
                  <option key={idx} value={status}>
                    {status}
                  </option>
                ))}
              </Form.Select>
            ) : field === "segment_name" ? (
              <Form.Control
                type="text"
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={isError}
                required
              />
            ) : field === "contact_person_for_scripting" ? (
              <Form.Select
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={!!isError}
              >
                <option value="">Select Contact Person</option>
                {contactPersonsScripting.length > 0
                  ? contactPersonsScripting.map((person, idx) => (
                      <option key={idx} value={person}>
                        {person}
                      </option>
                    ))
                  : // Fallback in case the API call hasn't loaded or returns empty
                    <option value="">No Data Available</option>
                }
              </Form.Select>
            ) : field === "contact_person_for_dp" ? (
              <Form.Select
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={!!isError}
              >
                <option value="">Select Contact Person</option>
                {contactPersonsDP.length > 0
                  ? contactPersonsDP.map((person, idx) => (
                      <option key={idx} value={person}>
                        {person}
                      </option>
                    ))
                  : <option value="">No Data Available</option>
                }
              </Form.Select>
            ) : field === "research_group_head" ? (
              <Form.Select
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={!!isError}
              >
                <option value="">Select Research Head</option>
                {contactPersonsResearch.length > 0
                  ? contactPersonsResearch.map((person, idx) => (
                      <option key={idx} value={person}>
                        {person}
                      </option>
                    ))
                  : // Optional fallback if no research names are fetched
                    contactPersonsResearchFallback.map((person, idx) => (
                      <option key={idx} value={person}>
                        {person}
                      </option>
                    ))
                }
              </Form.Select>
            ) : field === "contact_person_in_research" ? (
              <Form.Select
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={!!isError}
              >
                <option value="">Select Contact Person</option>
                {contactPersonsResearch.length > 0
                  ? contactPersonsResearch.map((person, idx) => (
                      <option key={idx} value={person}>
                        {person}
                      </option>
                    ))
                  : contactPersonsResearchFallback.map((person, idx) => (
                      <option key={idx} value={person}>
                        {person}
                      </option>
                    ))
                }
              </Form.Select>
            ) : field === "analysis_group_head" ? (
              <Form.Select
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={!!isError}
              >
                <option value="">Select Analysis Group Head</option>
                {analysisGroupHead.map((head, idx) => (
                  <option key={idx} value={head}>
                    {head}
                  </option>
                ))}
              </Form.Select>
            ) : field === "advanced_analytics" ? (
              <Form.Select
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={!!isError}
              >
                <option value="">Select Advanced Analytics</option>
                {advancedAnalyticsOptions.map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            ) : field === "survey_platform" ? (
              <Form.Select
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={!!isError}
              >
                <option value="">Select Survey Platform</option>
                {surveyPlatformOptions.map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            ) : field === "project_classification" ? (
              <Form.Select
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={!!isError}
              >
                <option value="">Select Classification</option>
                {projectClassification.map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            ) : field === "dp_platform" ? (
              <Form.Select
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={!!isError}
              >
                <option value="">Select DP Platform</option>
                {dpPlatformOptions.map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            ) : numericFields.includes(field) ? (
              <Form.Control
                type="text"
                value={formData[field] || ""}
                onChange={(e) => handleNumericChange(field, e.target.value)}
                isInvalid={!!isError}
              />
            ) : (
              <Form.Control
                type="text"
                value={formData[field] || ""}
                onChange={(e) => handleChange(field, e.target.value)}
                isInvalid={!!isError}
              />
            )}
            <Form.Control.Feedback type="invalid">
              {errors[`${field}_${segmentIndex}`]}
            </Form.Control.Feedback>
          </div>
        </Col>
      )
    });

  return (
    <div className="segment-details-container">
      <Form>
        <Card className="mb-4 shadow-sm">
          <Card.Header className="bg-primary text-white">
            <h5 className="mb-0">Reasearch and Analysis</h5>
          </Card.Header>
          <Card.Body>
            <Row className="gy-3">{renderFields(researchFields)}</Row>
          </Card.Body>
        </Card>

        <Card className="mb-4 shadow-sm">
          <Card.Header className="bg-secondary text-white">
            <h5 className="mb-0">Date Information</h5>
          </Card.Header>
          <Card.Body>
            <Row className="gy-4 gx-4">{renderFields(dateFields)}</Row>
          </Card.Body>
        </Card>

        <Card className="mb-4 shadow-sm">
          <Card.Header className="bg-success text-white">
            <h5 className="mb-0">Checks</h5>
          </Card.Header>
          <Card.Body>
            <Row className="gy-3">{renderFields(yesNoFields)}</Row>
          </Card.Body>
        </Card>

        <Card className="mb-4 shadow-sm">
          <Card.Header className="bg-dark text-white">
            <h5 className="mb-0">Additional Information</h5>
          </Card.Header>
          <Card.Body>
            <Row className="gy-3">{renderFields(additionalFields)}</Row>
          </Card.Body>
        </Card>
      </Form>
    </div>
  );
});

export default JTSegmentDetails;
