import React, { useState } from 'react';
import { Container, Form, Button, Card, Alert } from 'react-bootstrap';
import axios from 'axios';
import api from '../services/api';
const SignUpForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
  });
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [backendErrors, setBackendErrors] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate email domain on change
    if (name === 'email') {
      const domain = value.split('@')[1];
      if (domain && domain !== '4sight-global.com') {
        setEmailError('Email must be from the 4sight-global.com domain.');
      } else {
        setEmailError('');
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailDomain = formData.email.split('@')[1];
    if (emailDomain !== '4sight-global.com') {
      setErrorMessage('Please use an email address from the 4sight-global.com domain.');
      return;
    }
  
    try {
      const response = await api.post('users/signup/', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        setSuccessMessage('Sign Up successful!');
        setFormData({ email: '', username: '', password: '' });
        setErrorMessage('');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const backendError = error.response.data.error || 'Something went wrong';
        setErrorMessage(backendError);
      } else {
        setErrorMessage('Failed to sign up. Please try again later.');
      }
      setSuccessMessage('');
    }
  };
  

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Card style={{ width: '30rem', padding: '2rem' }}>
        <Card.Body>
          <Card.Title className="text-center">Sign Up</Card.Title>
          <hr />
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {backendErrors.length > 0 && (
            <Alert variant="danger">
              <ul>
                {backendErrors.map((err, index) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                isInvalid={!!emailError}
                required
              />
              <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicUsername" className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword" className="mb-4">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <div className="d-grid">
              <Button variant="primary" type="submit">
                Sign Up
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SignUpForm;
