import React, { useState, useEffect } from 'react';
import FileUpload from './ExcelUpload';


function Home() {
  const [data, setData] = useState(null);


  return (
    <div style={{ textAlign: "center", margin: "auto", paddingLeft: "100px" }}>
      <br />
      <FileUpload />
    </div>
  );
  
}



export default Home;
