import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Alert,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import api from "../services/api";

const FilterSummary = () => {
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(""); // Start date filter
  const [endDate, setEndDate] = useState(""); // End date filter

  useEffect(() => {
    fetchSummary();
  }, []);

  const fetchSummary = async () => {
    setLoading(true);
    setError(null);

    try {
      const email = localStorage.getItem("username");
      if (!email) throw new Error("No email found in localStorage");

      const params = new URLSearchParams();
      params.append("email", email);
      if (startDate) params.append("start_date", startDate);
      if (endDate) params.append("end_date", endDate);

      const response = await api.get(`timesheet/api/date/?${params.toString()}`);
      setSummary(response.data.today_summary);
    } catch (err) {
      setError(err.response?.data?.error || "Failed to fetch summary");
    } finally {
      setLoading(false);
    }
  };

  const handleFilterSubmit = () => {
    fetchSummary();
  };

  /**
   * Copies the summary table to clipboard with simple formatting
   */
  const copyTableToClipboard = () => {
    if (!summary) return;

    const tableElement = document.createElement("table");
    tableElement.style.border = "1px solid black";
    tableElement.style.borderCollapse = "collapse";

    // Table header
    const headerRow = document.createElement("tr");
    ["Project", "Task", "Duration (minutes)"].forEach((text) => {
      const th = document.createElement("th");
      th.innerText = text;
      th.style.border = "1px solid black";
      th.style.padding = "5px";
      headerRow.appendChild(th);
    });
    tableElement.appendChild(headerRow);

    // Table rows
    Object.keys(summary).forEach((project) => {
      Object.keys(summary[project]).forEach((task, taskIndex) => {
        const row = document.createElement("tr");

        if (taskIndex === 0) {
          const projectCell = document.createElement("td");
          projectCell.innerText = project;
          projectCell.style.border = "1px solid black";
          projectCell.style.padding = "5px";
          projectCell.rowSpan = Object.keys(summary[project]).length;
          row.appendChild(projectCell);
        }

        const taskCell = document.createElement("td");
        taskCell.innerText = task;
        taskCell.style.border = "1px solid black";
        taskCell.style.padding = "5px";
        row.appendChild(taskCell);

        const durationCell = document.createElement("td");
        durationCell.innerText = summary[project][task];
        durationCell.style.border = "1px solid black";
        durationCell.style.padding = "5px";
        row.appendChild(durationCell);

        tableElement.appendChild(row);
      });
    });

    // Temporarily attach the table to DOM, copy, then remove
    const tempDiv = document.createElement("div");
    tempDiv.appendChild(tableElement);
    document.body.appendChild(tempDiv);

    const range = document.createRange();
    range.selectNode(tempDiv);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();

    document.body.removeChild(tempDiv);
  };

  // -----------------------
  // Rendering logic
  // -----------------------
  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
        <Typography variant="body1" style={{ marginLeft: "10px" }}>
          Loading summary...
        </Typography>
      </div>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Paper elevation={3} style={{ padding: "24px", maxWidth: "800px", margin: "20px auto" }}>
      <Typography variant="h4" gutterBottom>
        Time Summary
      </Typography>

      {/* Date Filters */}
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <TextField
          type="date"
          label="Start Date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
        />
        <TextField
          type="date"
          label="End Date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={handleFilterSubmit}>
          Filter
        </Button>
      </div>

      {(!summary || Object.keys(summary).length === 0) ? (
        <Alert severity="info">No summary available.</Alert>
      ) : (
        <>
          <IconButton onClick={copyTableToClipboard} color="primary" aria-label="copy table" style={{ marginBottom: "10px" }}>
            <ContentCopyIcon />
          </IconButton>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Project</TableCell>
                  <TableCell>Task</TableCell>
                  <TableCell align="right">Duration (minutes)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(summary).map((project, projIndex) =>
                  Object.keys(summary[project]).map((task, taskIndex) => (
                    <TableRow key={`${projIndex}-${taskIndex}`}>
                      {taskIndex === 0 && (
                        <TableCell rowSpan={Object.keys(summary[project]).length}>
                          {project}
                        </TableCell>
                      )}
                      <TableCell>{task}</TableCell>
                      <TableCell align="right">{summary[project][task]}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Paper>
  );
};

export default FilterSummary;
