// src/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [token, setToken] = useState(null);
  const [lastLoggedIn, setLastLoggedIn] = useState(''); // New state for last login details
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const loggedInState = localStorage.getItem('isLoggedIn');
    const storedUsername = localStorage.getItem('username');
    const storedToken = localStorage.getItem('token');
    const storedLastLoggedIn = localStorage.getItem('lastLoggedIn');

    if (loggedInState === 'true' && storedToken) {
      setIsLoggedIn(true);
      setUsername(storedUsername || '');
      setToken(storedToken);
      setLastLoggedIn(storedLastLoggedIn || '');
    } else {
      setIsLoggedIn(false);
      setUsername('');
      setToken(null);
      setLastLoggedIn('');
    }
    setLoading(false);
  }, []);

  // Update handleLogin to accept lastLoggedIn
  const handleLogin = (user, authToken, lastLoginValue = '') => {
    setIsLoggedIn(true);
    setUsername(user);
    setToken(authToken);
    setLastLoggedIn(lastLoginValue);

    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('username', user);
    localStorage.setItem('token', authToken);
    localStorage.setItem('lastLoggedIn', lastLoginValue);
   // alert(lastLoginValue)
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    setToken(null);
    setLastLoggedIn('');

    localStorage.setItem('isLoggedIn', 'false');
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('Token');
    localStorage.removeItem('lastLoggedIn');
    //alert(lastLoggedIn)
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        username,
        token,
        lastLoggedIn, // Provide lastLoggedIn in context
        loading,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
