import React, { useState, useEffect } from "react";
import { Container, Form, Table, Spinner, Alert, Button } from "react-bootstrap";
import axios from "axios";
import api from '../services/api';
const questionLabels = {
  Q1_MeanScore: "Overall Performance",
  Q2_MeanScore: "Quality of the Script",
  Q3_MeanScore: "Timelines of the Script Delivery",
  Q4_MeanScore: "Quality of the Data Tables",
  Q5_MeanScore: "Timelines of the Data Tables Delivery",
  Q6_MeanScore: "Quality of Communication",
  Q7_MeanScore: "Availability and Support",
  row_count: "Total Number of Projects",
};

const SurveyInsights = () => {
  const [executives, setExecutives] = useState([]);
  const [selectedExecutive, setSelectedExecutive] = useState("");
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [typeOfWork, setTypeOfWork] = useState("dp");
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submissionLoading, setSubmissionLoading] = useState(false); // Spinner for submission

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem("Token");
        const response = await api.get(
          `survey/insights/?type_of_work=${typeOfWork}`,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        if (response.data && response.data.length > 0) {
          const validExecutives = response.data
            .map((item) => item.executive)
            .filter((executive) => executive !== "Not Applicable");
          setExecutives(validExecutives);
          setInsights(response.data);
        } else {
          setExecutives([]);
          setInsights([]);
          
        }
      } catch (err) {
        setError("Failed to fetch data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchInsights();
  }, [typeOfWork]);

  const handleExecutiveChange = (e) => {
    setSelectedExecutive(e.target.value);
    setSubmitSuccess(null); // Clear AI success response
    setSubmitError(null);   // Clear AI error response
  };
  
  const selectedInsights = insights.find(
    (insight) => insight.executive === selectedExecutive
  );

  const shouldHideDPRows = typeOfWork === "dp";
  const shouldHideScriptingRows = typeOfWork === "scripting";

  const sendDataToBackend = async () => {
    if (!selectedInsights) return;

    try {
        setSubmitError(null);
        setSubmitSuccess(null);
        setSubmissionLoading(true);

        const token = localStorage.getItem("Token");  // Get stored token

        const selectedQuestions =
            typeOfWork === "scripting"
                ? ["Q1_MeanScore", "Q2_MeanScore", "Q3_MeanScore", "Q4_MeanScore", "Q8_Responses"]
                : ["Q1_MeanScore", "Q5_MeanScore", "Q6_MeanScore", "Q7_MeanScore", "Q8_Responses"];

        const dataWithLabels = selectedQuestions.reduce((acc, key) => {
            if (selectedInsights[key] !== undefined) {
                acc[questionLabels[key] || key] = selectedInsights[key];
            }
            return acc;
        }, {});

        const response = await api.post(
            "survey/submit_insights/",
            { data: dataWithLabels },
            {
                headers: { 
                    "Authorization": `Token ${token}`, // Include token
                    "Content-Type": "application/json",
                },
            }
        );

        const insights = response.data.insights.replace(/\n/g, "<br/>");
        setSubmitSuccess(insights);

    } catch (err) {
        setSubmitError("Failed to send data. Please try again.");
    } finally {
        setSubmissionLoading(false);
    }
};


  return (
    <Container className="mt-5">
      <h2 className="text-center">Survey Insights</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form.Group controlId="typeOfWorkSelect" className="mb-4">
        <Form.Label>Select Type of Work</Form.Label>
        <Form.Control
          as="select"
          value={typeOfWork}
          onChange={(e) => {
            setTypeOfWork(e.target.value);
            setSelectedExecutive("");
          }}
        >
          <option value="dp">DP</option>
          <option value="scripting">Scripting</option>
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="executiveSelect" className="mb-4">
        <Form.Label>Select Executive</Form.Label>
        <Form.Control
          as="select"
          value={selectedExecutive}
          onChange={handleExecutiveChange}
          disabled={loading || executives.length === 0}
        >
          <option value="">-- Select Executive --</option>
          {executives.map((executive, index) => (
            <option key={index} value={executive}>
              {executive}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      {loading && (
        <div className="text-center">
          <Spinner animation="border" />
          <p>Loading...</p>
        </div>
      )}
      {selectedInsights && (
        <>
          <Table striped bordered hover responsive className="mt-4">
            <thead>
              <tr>
                <th>Question</th>
                <th>Mean Score</th>
              </tr>
            </thead>
            <tbody>
              {!shouldHideDPRows && (
                <>
                  <tr>
                    <td>Total Number of projects</td>
                    <td>{selectedInsights.row_count || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Overall Performance</td>
                    <td>{selectedInsights.Q1_MeanScore || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Quality of the Script</td>
                    <td>{selectedInsights.Q2_MeanScore || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Timelines of the Script Delivery</td>
                    <td>{selectedInsights.Q3_MeanScore || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Quality of the Data Tables</td>
                    <td>{selectedInsights.Q4_MeanScore || "N/A"}</td>
                  </tr>
                </>
              )}
              {!shouldHideScriptingRows && (
                <>
                  <tr>
                    <td>Total Number of projects</td>
                    <td>{selectedInsights.row_count || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Overall Performance</td>
                    <td>{selectedInsights.Q1_MeanScore || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Timelines of the Data Tables Delivery</td>
                    <td>{selectedInsights.Q5_MeanScore || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Quality of Communication</td>
                    <td>{selectedInsights.Q6_MeanScore || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Availability and Support</td>
                    <td>{selectedInsights.Q7_MeanScore || "N/A"}</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          <div className="mt-4">
            <h5>Q8 Responses:</h5>
            {selectedInsights.Q8_Responses && selectedInsights.Q8_Responses.length > 0 ? (
              <ul>
                {selectedInsights.Q8_Responses.map((response, index) =>
                  response ? (
                    <li key={index}>{response}</li>
                  ) : (
                    <li key={index} className="text-muted">
                      No response
                    </li>
                  )
                )}
              </ul>
            ) : (
              <p>No Q8 responses available.</p>
            )}
          </div>
          <Button
            variant="primary"
            className="mt-3"
            onClick={sendDataToBackend}
            disabled={submissionLoading} // Disable button while submitting
          >
            {submissionLoading ? <Spinner animation="border" size="sm" /> : "Submit Data for AI Insights"}
          </Button>
          {submitSuccess && (
            <Alert variant="success" className="mt-3">
              <div dangerouslySetInnerHTML={{ __html: submitSuccess }} />
            </Alert>
          )}
          {submitError && (
            <Alert variant="danger" className="mt-3">
              {submitError}
            </Alert>
          )}
        </>
      )}
      {!loading && !selectedInsights && selectedExecutive && (
        <Alert variant="info" className="mt-4">
          No insights available for the selected executive.
        </Alert>
      )}
    </Container>
  );
};

export default SurveyInsights;
