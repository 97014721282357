import React, { useState, useEffect } from "react";
import { Form, Spinner, Alert, Table, Row, Col } from "react-bootstrap";
import axios from "axios";
import api from '../services/api';
const JTMonthWiseReport = () => {
  const [months, setMonths] = useState([]); // Store distinct months
  const [years, setYears] = useState([]); // Store distinct years
  const [summaryData, setSummaryData] = useState([]); // Store summary data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  useEffect(() => {
    const fetchYears = async () => {
      try {
        setLoading(true);

        // Fetch distinct years
        const yearsResponse = await api.get(
          "api/segments/distinct_completed_years/",
          {
            headers: { Authorization: `Token ${localStorage.getItem('Token')}` },
            params: { user: localStorage.getItem('username') },
          }
        );
        setYears(yearsResponse.data.filter(Boolean));
      } catch (err) {
        setError("Failed to fetch year data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchYears();
  }, []); // This will run only once, when the component is mounted

  // Fetch distinct months based on selected year
  useEffect(() => {
    if (!selectedYear) return; // If no year is selected, don't fetch months

    const fetchMonths = async () => {
      try {
        setLoading(true);

        // Fetch distinct months for the selected year
        const monthsResponse = await api.get(
          "api/segments/distinct_completed_months/",
          {
            headers: { Authorization: `Token ${localStorage.getItem('Token')}` },
            params: { 
              user: localStorage.getItem('username'),
              year: selectedYear, // Pass the selected year here
            },
          }
        );
        setMonths(monthsResponse.data.filter(Boolean));
      } catch (err) {
        setError("Failed to fetch months data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchMonths();
  }, [selectedYear]); // This will run every time the selected year changes

  
  // Fetch summary data
  const fetchSummaryData = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        "api/segments/project_summary/",
        {
          headers: { Authorization: `Token ${localStorage.getItem('Token')}` },
            
          params: {
            completed_month: selectedMonth || undefined,
            year: selectedYear || undefined,
            user: localStorage.getItem('username')
          },
        }
      );
      setSummaryData(response.data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch summary data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = () => {
    if (selectedMonth || selectedYear) {
      fetchSummaryData();
    } else {
      setSummaryData([]); // Clear summary data if no filter is applied
    }
  };

  // Extract platform data dynamically
  const extractPlatformData = (platformType) => {
    const platformKeys = Object.keys(summaryData[0] || {}).filter((key) =>
      key.startsWith(`${platformType}_platform`)
    );

    const platforms = {};
    platformKeys.forEach((key) => {
      const [platform, status] = key
        .replace(`${platformType}_platform_`, "")
        .split("_status_");
      if (!platforms[platform]) {
        platforms[platform] = { Open: 0, Closed: 0 };
      }
      platforms[platform][status] = summaryData.reduce(
        (sum, item) => sum + (item[key] || 0),
        0
      );
    });

    return platforms;
  };

  const surveyPlatforms = extractPlatformData("survey");
  const dpPlatforms = extractPlatformData("dp");

  return (
    <>
      {loading && (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
          <p>Loading...</p>
        </div>
      )}
      {error && (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      )}
      {!loading && !error && (
        <>
          <Row>
            <Col>
              <Form.Group controlId="yearSelect" className="mb-4">
                <Form.Label>Select Completed Year</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  <option value="">-- Select Year --</option>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="monthSelect" className="mb-4">
                <Form.Label>Select Completed Month</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  <option value="">-- Select Month --</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <button onClick={handleFilterChange} className="btn btn-primary mb-4">
            Apply Filters
          </button>

          {summaryData.length > 0 ? (
            <>
              <h4>Contact Persons</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>In Research</th>
                    <th>For Scripting</th>
                    <th>For DP</th>
                    <th>Project Names</th>
                  </tr>
                </thead>
                <tbody>
  {summaryData.map((item, index) => (
    <tr key={index}>
      <td>{item.contact_person_in_research || "N/A"}</td>
      <td>{item.contact_person_for_scripting || "N/A"}</td>
      <td>{item.contact_person_for_dp || "N/A"}</td>
      <td>
        {item.project_name
          ? item.project_name.split(",").map((name) => name.trim()).join(", ")
          : "N/A"}
      </td>
    </tr>
  ))}
</tbody>

              </Table>

              <h4>Status Summary</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Open</td>
                    <td>
                      {summaryData.reduce(
                        (sum, item) => sum + (item.status_Open_count || 0),
                        0
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Closed</td>
                    <td>
                      {summaryData.reduce(
                        (sum, item) => sum + (item.status_Closed_count || 0),
                        0
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <h4>Survey Platform Summary</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Platform</th>
                    <th>Open</th>
                    <th>Closed</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(surveyPlatforms).map(([platform, stats], index) => (
                    <tr key={index}>
                      <td>{platform}</td>
                      <td>{stats.Open}</td>
                      <td>{stats.Closed}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <h4>DP Platform Summary</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Platform</th>
                    <th>Open</th>
                    <th>Closed</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(dpPlatforms).map(([platform, stats], index) => (
                    <tr key={index}>
                      <td>{platform}</td>
                      <td>{stats.Open}</td>
                      <td>{stats.Closed}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <h4>Platform Summary</h4>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>Project Name</th>
      <th>Survey Platforms</th>
      <th>DP Platforms</th>
    </tr>
  </thead>
  <tbody>
    {summaryData.map((item, index) => (
      <tr key={index}>
        <td>{item.project_name || "N/A"}</td>
        <td>
          {Object.entries(item)
            .filter(([key]) => key.startsWith("survey_platform_"))
            .map(([key]) =>
              key.replace("survey_platform_", "").replace(/_status_.*/, "")
            )
            .join(", ")}
        </td>
        <td>
          {Object.entries(item)
            .filter(([key]) => key.startsWith("dp_platform_"))
            .map(([key]) =>
              key.replace("dp_platform_", "").replace(/_status_.*/, "")
            )
            .join(", ")}
        </td>
      </tr>
    ))}
  </tbody>
</Table>
<h4>Survey Platform Summary</h4>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>Project Name</th>
      {Object.keys(
        summaryData[0] || {}
      )
        .filter((key) => key.startsWith("survey_platform_"))
        .map((key) => (
          <th key={key}>
            {key
              .replace("survey_platform_", "")
              .replace(/_status_.*/, "")}
          </th>
        ))}
    </tr>
  </thead>
  <tbody>
    {summaryData.map((item, index) => (
      <tr key={index}>
        <td>{item.project_name || "N/A"}</td>
        {Object.keys(
          summaryData[0] || {}
        )
          .filter((key) => key.startsWith("survey_platform_"))
          .map((key) => (
            <td key={key}>{item[key] || 0}</td>
          ))}
      </tr>
    ))}
  </tbody>
</Table>

<h4>DP Platform Summary</h4>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>Project Name</th>
      {Object.keys(
        summaryData[0] || {}
      )
        .filter((key) => key.startsWith("dp_platform_"))
        .map((key) => (
          <th key={key}>
            {key
              .replace("dp_platform_", "")
              .replace(/_status_.*/, "")}
          </th>
        ))}
    </tr>
  </thead>
  <tbody>
    {summaryData.map((item, index) => (
      <tr key={index}>
        <td>{item.project_name || "N/A"}</td>
        {Object.keys(
          summaryData[0] || {}
        )
          .filter((key) => key.startsWith("dp_platform_"))
          .map((key) => (
            <td key={key}>{item[key] || 0}</td>
          ))}
      </tr>
    ))}
  </tbody>
</Table>

            </>
          ) : (
            <Alert variant="info">No data available for the selected filters.</Alert>
          )}
        </>
      )}
    </>
  );
};

export default JTMonthWiseReport;
