import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Button,
  Container,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import JTSegmentDetails from "./JTSegmentDetails";
import JTFormFields from "./JTFormFields";
import api from '../services/api';

const JTNewEntry = () => {
  const segmentDetailsRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const { jobNumber, editMode: initialEditMode } = location.state || {};

  const [formData, setFormData] = useState({
    unit: "",
    year: "",
    month: "",
    project_name: "",
    job_number: "",
    client: "",
    no_of_segments: 1, // Default to 1 segment
  });

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
  ];

  const [segmentsData, setSegmentsData] = useState([]);
  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({}); // For validation errors
  const [showErrorAlert, setShowErrorAlert] = useState(false); // To control Alert visibility

  const [isEditMode, setIsEditMode] = useState(initialEditMode || false); // Separate state for editMode

  // Default fields for each segment (Include no_of_segments)
  const defaultSegmentFields = {
    no_of_segments: 1, // Will be updated based on position
    unit: "",
    year: "",
    month: "",
    project_name: "",
    job_number: "",
    client: "",
    segment_name: "",
    completed_month: "",
    data_correction_done: "No", // Default to "No"
    status: "",
    research_group_head: "",
    contact_person_in_research: "",
    analysis_group_head: "",
    contact_person_for_scripting: "",
    contact_person_for_dp: "",
    expected_date_of_link: null,
    actual_date_of_link: null,
    expected_date_of_final_output: null,
    actual_date_of_final_output: null,
    fw_end_date: null,
    fw_start_date: null,
    ap_received_date: null,
    qnr_received_date: null,
    latest_topline_date: null,
    final_awn_date: null,
    date_of_awn: null,
    check_list_followed: "No", // Default to "No"
    feedback_survey_done: "No", // Default to "No"
    remarks: "",
    archive_taken: "No", // Default to "No"
    loi: "",
    number_of_responses: "",
    oe_costing: "",
    oe_coding_done: "No", // Default to "No"
    no_of_top_lines: "",
    final_processed_samples: null,
    achieved_sample_size: null,
    actual_sample_size: null,
    advanced_analytics: "",
    norms_done: "No", // Default to "No"
    survey_platform: "",
    dp_platform: "",
    backup_taken: "No", // Default to "No"
    backup_folder_path: "",
    project_classification: "",
  };

  useEffect(() => {
    const fetchProjectData = async () => {
      setLoading(true);

      if (isEditMode && jobNumber) {
        try {
          const response = await api.get(
            `api/segments/job/${encodeURIComponent(jobNumber)}/`,
            {
              headers: {
                Authorization: `Token ${localStorage.getItem("Token")}`,
              },
            }
          );

          const projectData = response.data;

          setFormData({
            unit: projectData.unit || "",
            year: projectData.year || "",
            month: projectData.month || "",
            project_name: projectData.project_name || projectData.projectName || "", // Ensure correct key
            job_number: projectData.job_number || projectData.jobNumber || "", // Ensure correct key
            client: projectData.client || "",
            no_of_segments: projectData.segments?.length || 1,
          });

          // Ensure segment name is correctly populated
          setSegmentsData(
            projectData.segments?.map((segment, index) => ({
              ...defaultSegmentFields,
              ...segment, // Ensure data from API is merged correctly
              no_of_segments: index + 1,
            })) || []
          );

        } catch (error) {
          console.error("Failed to fetch project data:", error);
          alert("Failed to fetch project data. Please check the console for details.");
        } finally {
          setLoading(false);
        }
      } else {
        // Create mode: initialize empty segments with all fields
        const initialSegments = Array.from({ length: formData.no_of_segments }, (_, index) => ({
          ...defaultSegmentFields,
          no_of_segments: index + 1, // Assign no_of_segments based on position
        }));
        setSegmentsData(initialSegments);
        setLoading(false);
      }
    };

    fetchProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, jobNumber]);

  // Dynamically update segments when no_of_segments changes in create-new and edit modes
  useEffect(() => {
    if (isEditMode) {
      const currentLength = segmentsData.length;
      const desiredLength = formData.no_of_segments;

      if (desiredLength > currentLength) {
        // Add new segments with default fields
        const additionalSegments = Array.from(
          { length: desiredLength - currentLength },
          (_, index) => ({
            ...defaultSegmentFields,
            no_of_segments: currentLength + index + 1, // Assign correct no_of_segments
          })
        );
        setSegmentsData([...segmentsData, ...additionalSegments]);
      } else if (desiredLength < currentLength) {
        // Remove excess segments
        setSegmentsData(segmentsData.slice(0, desiredLength));
        // Adjust currentSegmentIndex if necessary
        if (currentSegmentIndex >= desiredLength) {
          setCurrentSegmentIndex(desiredLength - 1);
        }
      } else {
        // If no_of_segments is unchanged, ensure no_of_segments in each segment is correct
        const updatedSegments = segmentsData.map((segment, index) => ({
          ...segment,
          no_of_segments: index + 1,
        }));
        setSegmentsData(updatedSegments);
      }
    } else {
      // Create mode: Initialize segments based on no_of_segments
      const initialSegments = Array.from({ length: formData.no_of_segments }, (_, index) => ({
        ...defaultSegmentFields,
        no_of_segments: index + 1, // Assign no_of_segments based on position
      }));
      setSegmentsData(initialSegments);
      setCurrentSegmentIndex(0); // Reset to the first segment
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.no_of_segments, isEditMode]);

  const handleSegmentNumberChange = (value) => {
    const newSegmentNumber = parseInt(value, 10) || 1;

    setFormData((prevFormData) => ({
      ...prevFormData,
      no_of_segments: newSegmentNumber,
    }));
  };

  const handleSegmentChange = (field, value) => {
    const updatedSegments = [...segmentsData];
    if (field === "actual_date_of_final_output" && value) {
      const date = new Date(value);
      if (!isNaN(date)) {
        const monthName = months[date.getMonth()]; // Extract month name
        updatedSegments[currentSegmentIndex]["completed_month"] = monthName;
      }
    }
    // Ensure only numeric fields are transformed appropriately
    if (["final_processed_samples", "actual_sample_size", "achieved_sample_size"].includes(field)) {
      updatedSegments[currentSegmentIndex][field] = value === "" || isNaN(value) ? null : Number(value);
    } else {
      updatedSegments[currentSegmentIndex][field] = value;
    }
  
    setSegmentsData(updatedSegments); // Update the state
  };

  const validateForm = () => {
    const newErrors = {};
  
    // Validate main form fields
    if (!formData.month) newErrors.month = "Month is required.";
    if (!formData.unit) newErrors.unit = "Unit is required.";
    if (!formData.project_name) newErrors.project_name = "Project Name is required.";
  
    // Validate year
    if (!formData.year) {
      newErrors.year = "Year is required.";
    } else if (!/^\d{4}$/.test(formData.year)) {
      newErrors.year = "Year must be a 4-digit number.";
    }
  
    // Validate segment_name for the current segment
    const currentSegment = segmentsData[currentSegmentIndex];
    if (!currentSegment.segment_name || currentSegment.segment_name.trim() === "") {
      newErrors["segment_name"] = "Segment name is required.";
    }
  
    // Only apply mandatory validation if status is "Closed"
    if (currentSegment.status === "Closed") {
      const mandatoryFields = [
        "unit", "year", "month", "project_name", "job_number", "segment_name",
        "data_correction_done", "status", "research_group_head", "contact_person_in_research",
        "analysis_group_head", "contact_person_for_scripting", "contact_person_for_dp",
        "expected_date_of_link", "actual_date_of_link", "expected_date_of_final_output",
        "actual_date_of_final_output", "fw_end_date", "fw_start_date", "ap_received_date",
        "qnr_received_date", "check_list_followed", "feedback_survey_done", "archive_taken",
         "no_of_top_lines", "final_processed_samples",
        "achieved_sample_size", "actual_sample_size", "advanced_analytics", "norms_done",
        "survey_platform", "dp_platform", "backup_taken",  "project_classification",
      ];
  
      for (const field of mandatoryFields) {
        const value = currentSegment[field];
  
        if (
          value === null || value === undefined ||
          (typeof value === "string" && value.trim() === "")
        ) {
          newErrors[field] = `${field.replace(/_/g, " ")} is required when project is closed.`;
        }
      }
    }
  
    setErrors(newErrors);
  
    // Show Alert if there are errors
    if (Object.keys(newErrors).length > 0) {
      setShowErrorAlert(true);
    } else {
      setShowErrorAlert(false);
    }
  
    return Object.keys(newErrors).length === 0;
  };
  

  const handleSubmit = async () => {
    // Ensure the form is valid before submitting
    if (!validateForm()) return;

    console.log("Segments Data Before Submission:", segmentsData);

    // Separate existing segments (those with ids) and new segments (those without ids)
    const existingSegments = segmentsData.filter((segment) => segment.id);
    const newSegments = segmentsData.filter((segment) => !segment.id);

    try {
      if (isEditMode) {
        // If editing existing segments, update them on the server
        if (existingSegments.length > 0) {
          const cleanedSegments = existingSegments.map((segment) =>
            Object.fromEntries(Object.entries(segment).filter(([_, value]) => value !== null))
          );

          const payload = {
            ...formData,
            created_by: localStorage.getItem("username"),
            segments: cleanedSegments,
          };

          // Update segments via a PATCH request
          await api.patch(
            `api/segments/${encodeURIComponent(jobNumber)}/update-segments/`,
            payload,
            {
              headers: {
                Authorization: `Token ${localStorage.getItem("Token")}`,
              },
            }
          );
        }

        // Handle new segments (those that don't have an id)
        if (newSegments.length > 0) {
          for (const newSegment of newSegments) {
            const payload = {
              ...formData,
              segments: [newSegment],
            };

            // Post new segment data
            await api.post("api/segments/", payload, {
              headers: {
                Authorization: `Token ${localStorage.getItem("Token")}`,
              },
            });
          }
        }
      } else {
        // If it's a new submission (not editing), create new segments
        const payload = {
          ...formData,
          created_by: localStorage.getItem("username"),
          segments: segmentsData,
        };

        // Post the new segments data
        await api.post("api/segments/", payload, {
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
          },
        });
      }

      // Ensure the segmentDetailsRef is properly initialized
      if (segmentDetailsRef.current) {
        const status = segmentDetailsRef.current.formData?.status; // Access the status from formData
        const feedbackSurveyDone = segmentDetailsRef.current.formData?.feedback_survey_done; // Access feedback_survey_done

        if (status || feedbackSurveyDone !== undefined) {
          segmentDetailsRef.current.handleStatusChange(status, feedbackSurveyDone); // Trigger handleStatusChange with the status
        }
      } else {
        console.log("Error: Segment details ref is not defined");
      }

      // Alert the user that the project has been saved successfully
      alert("Project saved successfully.");

      // Navigate to the projects list page
      navigate("/projects_list");

    } catch (error) {
      console.error("Error:", error.response?.data || error.message);

      // Show an alert if an error occurred during the save operation
      alert(`Failed to save project: ${error.response?.data?.detail || error.message}`);
    }
  };

  // Scroll to the top when showErrorAlert changes to true
  useEffect(() => {
    if (showErrorAlert) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [showErrorAlert]);

  if (loading) {
    return (
      <Container className="text-center my-5">
        <Spinner animation="border" variant="primary" />
        <p className="mt-3">Loading project data...</p>
      </Container>
    );
  }

  return (
    <Container>
      <Form>
        {showErrorAlert && (
          <Alert
            variant="danger"
            onClose={() => setShowErrorAlert(false)}
            dismissible
          >
            <Alert.Heading>Form Submission Failed</Alert.Heading>
            <ul className="mb-0">
              {Object.values(errors).map((error, idx) => (
                <li key={idx}>{error}</li>
              ))}
            </ul>
          </Alert>
        )}

        <JTFormFields
          formData={formData}
          setFormData={setFormData}
          handleSegmentNumberChange={handleSegmentNumberChange}
          months={months}
          errors={errors}
          isEditMode={isEditMode} // Pass isEditMode as a separate prop
        />

        <h4 className="mt-4">Segment Details</h4>
        {segmentsData.length > 0 && (
          <JTSegmentDetails
            formData={segmentsData[currentSegmentIndex]}
            handleChange={handleSegmentChange}
            ref={segmentDetailsRef}
            errors={errors} // Pass errors to JTSegmentDetails
            segmentIndex={currentSegmentIndex} // Pass the current segment index
          />
        )}

        <div className="d-flex justify-content-between mt-3">
          <Button
            variant="secondary"
            onClick={() =>
              setCurrentSegmentIndex((prev) => Math.max(prev - 1, 0))
            }
            disabled={currentSegmentIndex === 0}
          >
            Previous
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              setCurrentSegmentIndex((prev) =>
                Math.min(prev + 1, segmentsData.length - 1)
              )
            }
            disabled={currentSegmentIndex === segmentsData.length - 1}
          >
            Next
          </Button>
        </div>

        <Button className="mt-3" variant="primary" onClick={handleSubmit}>
          {isEditMode ? "Save Changes" : "Create Project"}
        </Button>
      </Form>
    </Container>
  );
};

export default JTNewEntry;