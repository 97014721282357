// emailService.js
import api from '../services/api';

export const sendEmailNotification = (awnDetails) => {
  // Construct the email HTML content with header, container, and footer
  const emailHtml = `
    <div style="font-family: Arial, sans-serif; color: #333; max-width:400px; margin:0 auto; border:1px solid #ddd;">
      <!-- Header -->
      <div style="background-color:rgb(3, 58, 76); padding: 20px; text-align: center;">
        <h1 style="margin: 0; font-size: 24px;">4SIGHT OPERATIONS</h1>
      </div>
      
      <!-- Container -->
      <div style="padding: 20px;">
        <h2>AWN Entry Created Successfully</h2>
        <p>The AWN entry for project <strong>${awnDetails.projectName}</strong> has been created.</p>
        <p>Job Number: ${awnDetails.jobNumber}</p>
        <!-- Additional AWN details can be added here -->
        <p>Thank you.</p>
      </div>
      
      <!-- Footer -->
      <div style="background-color:rgb(3, 58, 76); padding: 20px; text-align: center;">
        <p style="margin: 0; font-size: 14px;">Footer</p>
      </div>
    </div>
  `;

  // Prepare the payload to send to the backend API, including the "from" field with a display name
  const emailPayload = {
    from: '4SIGHT OPERATIONS <no-reply@4sightoperations.com>',
    to: 'ram@4sight-global.com',
    subject: 'AWN Entry Created Successfully',
    html: emailHtml,
    attachments: awnDetails.awnAttachment
      ? [
          {
            filename: `4SiGHT_Analysis_Warning Note_${awnDetails.projectName}.xlsx`,
            content: awnDetails.awnAttachment, // Base64 encoded content
            encoding: 'base64'
          }
        ]
      : []
  };

  // Trigger the API call to send the email and return the Promise
  return api.post('api/awn/send-email', emailPayload)
    .then((response) => {
      console.log('Email sent successfully:', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error('Failed to send email:', error);
      throw error;
    });
};
